import {
    type DataRef,
    type DragEndEvent,
    type DragStartEvent, DndContext, DragOverlay,
    MouseSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import ElementDragOverlay from 'components/dist/molecules/ElementDragOverlay';
import { GlobalShoeBoxProps } from 'components/dist/organisms/GlobalShoeBox';
import { useState } from 'react';
import { ShoeboxItemResponseDto } from 'src/backend';
import { useShoeBoxItemViewerContext } from 'src/components/dashboard/shoebox-item-viewer/shoebox-item-viewer.context';
import { useFormElementContext } from 'src/contexts/form-element-context';
import { useUploadFormElementContext } from 'src/contexts/upload-form-element-context/upload-form-element-context';
import { useAuth } from 'src/hooks/use-auth';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';

interface FormElementDataType {
    type: "FORM_ELEMENT";
    value: FormElementV2ResponseDtoExtended[];
}

interface ShoeBoxItemDataType {
    type: "SHOEBOX_ITEM";
    value: ShoeboxItemResponseDto[];
}

interface MyShoeboxDataType {
    type: "MY_SHOEBOX";
}
interface LoanUserShoeboxDataType {
    type: "LOAN_USER_SHOEBOX";
    userId: string;
    loanId: string;
    userDisplayName: string;
}

interface LoanShoeboxDataType {
    type: "LOAN_SHOEBOX";
    loanId: string;
}

interface CustomDragEndEvent extends DragEndEvent {
    over: Omit<DragEndEvent['over'], 'data'> & {
        data: DataRef<FormElementDataType>
    } | Omit<DragEndEvent['over'], 'data'> & {
        data: DataRef<ShoeBoxItemDataType>
    } | Omit<DragEndEvent['over'], 'data'> & {
        data: DataRef<MyShoeboxDataType>
    } | Omit<DragEndEvent['over'], 'data'> & {
        data: DataRef<LoanShoeboxDataType>
    } | Omit<DragEndEvent['over'], 'data'> & {
        data: DataRef<LoanUserShoeboxDataType>
    },
    active: Omit<DragStartEvent['active'], 'data'> & {
        data: DataRef<FormElementDataType>
    } | Omit<DragStartEvent['active'], 'data'> & {
        data: DataRef<ShoeBoxItemDataType>
    }
}

interface CustomDragStartEvent extends DragStartEvent {
    active: Omit<DragStartEvent['active'], 'data'> & {
        data: DataRef<FormElementDataType>
    } | Omit<DragStartEvent['active'], 'data'> & {
        data: DataRef<ShoeBoxItemDataType>
    }
}

export const DndProvider = ({ children }) => {
    const { user } = useAuth();
    const uploadFormElementContext = useUploadFormElementContext();
    const formElementContext = useFormElementContext();
    const shoeboxItemViewerContext = useShoeBoxItemViewerContext();
    const [draggedItems, setDraggedItems] = useState<FormElementDataType | ShoeBoxItemDataType>({
        type: null,
        value: []
    });

    const onDragStart = (event: CustomDragStartEvent) => {
        const current = event.active.data.current;
        const { type, value } = current;
        if (type === "FORM_ELEMENT") {
            const uniqueMergedElements = [
                ...formElementContext.multiSelect.formElements,
                ...value
            ].filter((element, index, self) => self.findIndex((e) => e.id === element.id) === index);
            setDraggedItems({
                type: 'FORM_ELEMENT',
                value: uniqueMergedElements
            });
        } else if (type === "SHOEBOX_ITEM") {
            setDraggedItems(current);
        }
    };

    const onDragEnd = (event: CustomDragEndEvent) => {
        const { over } = event;
        if (!over || draggedItems.value.length === 0) return;
        const activeData = draggedItems;
        const overData = over.data.current;
        setDraggedItems({
            type: null,
            value: []
        });
        if (activeData.type === "FORM_ELEMENT" && overData.type === "FORM_ELEMENT") {
            const [targetElement] = overData.value;
            const onDropItems = activeData.value
                // if target is also dragged element, then remove it from the list
                .filter((element) => element.id !== targetElement.id)
                .map((element) => ({
                    title: element.title,
                    id: element.id,
                    document: element.answer?.document,
                    loanId: targetElement.loanId,
                    type: "FORM_ELEMENT" as const
                }));
            return uploadFormElementContext.onDropElements(targetElement, onDropItems);
        } else if (activeData.type === "SHOEBOX_ITEM" && overData.type === "FORM_ELEMENT") {
            const [targetElement] = overData.value;
            return uploadFormElementContext.onDropElements(targetElement, activeData.value.map((item) => ({
                title: item.title,
                id: item.id,
                document: item.document,
                loanId: targetElement.loanId,
                type: "SHOEBOX_ITEM"
            })));
        } else if (activeData.type === "SHOEBOX_ITEM" && overData.type === "MY_SHOEBOX") {
            const items: GlobalShoeBoxProps['folders'][0]['files'] = activeData.value.map((item) => ({
                title: item.title,
                id: item.id,
                uploadProgress: 0,
                loanId: null,
                shoeboxType: "PERSONAL",
                uploadedById: user.id,
                ownerId: user.id,
                documentName: item.document.name,
                uploading: false,
                documentId: item.document.id,
                type: "SHOEBOX_ITEM"
            }));
            shoeboxItemViewerContext.onMoveItemsBackToShoeBox(items);
        } else if (activeData.type === "FORM_ELEMENT" && overData.type === "LOAN_SHOEBOX") {
            const items: GlobalShoeBoxProps['folders'][0]['files'] = activeData.value
                .filter((element) => !!element.answer?.document)
                .map((element) => ({
                    title: element.answer?.document?.name,
                    id: element.id,
                    uploadProgress: 0,
                    loanId: overData.loanId,
                    shoeboxType: "LOAN",
                    uploadedById: user.id,
                    ownerId: user.id,
                    documentName: element.answer?.document?.name,
                    uploading: false,
                    documentId: element.answer?.document?.id,
                    type: "FORM_ELEMENT"
                }));

            if (items.length > 0) {
                shoeboxItemViewerContext.onMoveItemsBackToLoanShoeBox(items, 'your');
                uploadFormElementContext.onDeleteAnswerFromElements(activeData.value);
            }
        } else if (activeData.type === "FORM_ELEMENT" && overData.type === "MY_SHOEBOX") {
            const items: GlobalShoeBoxProps['folders'][0]['files'] = activeData.value
                .filter((element) => !!element.answer?.document)
                .map((element) => ({
                    title: element.answer?.document?.name,
                    id: element.id,
                    uploadProgress: 0,
                    loanId: null,
                    shoeboxType: "PERSONAL",
                    uploadedById: user.id,
                    ownerId: user.id,
                    documentName: element.answer?.document?.name,
                    uploading: false,
                    documentId: element.answer?.document?.id,
                    type: "SHOEBOX_ITEM"
                }));

            if (items.length > 0) {
                shoeboxItemViewerContext.onMoveElementBackToShoeBox(items);
                uploadFormElementContext.onDeleteAnswerFromElements(activeData.value);
            }
        } else if (activeData.type === "SHOEBOX_ITEM" && overData.type === "LOAN_USER_SHOEBOX") {

            const items: GlobalShoeBoxProps['folders'][0]['files'] = activeData.value.map((item) => ({
                title: item.title,
                id: item.id,
                uploadProgress: 0,
                loanId: overData.loanId,
                shoeboxType: "LOAN",
                uploadedById: overData.userId,
                ownerId: overData.userId,
                documentName: item.document.name,
                uploading: false,
                documentId: item.document.id,
                type: "SHOEBOX_ITEM"
            }));
            shoeboxItemViewerContext.onMoveShoeboxItemsToUser(overData.userId, overData.userDisplayName, items);
        } else if (activeData.type === "FORM_ELEMENT" && overData.type === "LOAN_USER_SHOEBOX") {
            const items: GlobalShoeBoxProps['folders'][0]['files'] = activeData.value
                .filter((element) => !!element.answer?.document)
                .map((element) => ({
                    title: element.answer?.document?.name,
                    id: null,
                    uploadProgress: 0,
                    loanId: overData.loanId,
                    shoeboxType: "LOAN",
                    uploadedById: overData.userId,
                    ownerId: overData.userId,
                    documentName: element.answer?.document?.name,
                    uploading: false,
                    documentId: element.answer?.document?.id,
                    type: "FORM_ELEMENT"
                }));

            if (items.length > 0) {
                shoeboxItemViewerContext.onMoveItemsBackToLoanShoeBox(items, overData.userDisplayName);
                uploadFormElementContext.onDeleteAnswerFromElements(activeData.value);
            }
        }
    };

    const pointerSensor = useSensor(PointerSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
    });

    const mouseSensor = useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
    });

    const touchSensor = useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    });

    const sensors = useSensors(pointerSensor, mouseSensor, touchSensor);
    const elementDragOverlay = getDragOverlay(draggedItems);
    return (
        <DndContext
            autoScroll
            modifiers={[snapCenterToCursor, restrictToWindowEdges]}
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            {children}
            {elementDragOverlay && <DragOverlay
                zIndex={1400}
                className='z-splash'>
                <ElementDragOverlay
                    className="w-40"
                    title={elementDragOverlay.title}
                    type={elementDragOverlay.type}
                    documentName={elementDragOverlay.documentName}
                    count={elementDragOverlay.count}
                />
            </DragOverlay>}
        </DndContext>
    )
}

const getDragOverlay = (draggedItems: FormElementDataType | ShoeBoxItemDataType) => {
    if (draggedItems.type === "FORM_ELEMENT") {
        return {
            type: draggedItems.value[0].storageType,
            title: draggedItems.value[0].title,
            documentName: draggedItems.value[0].answer?.document?.name,
            count: draggedItems.value.length
        };
    } else if (draggedItems.type === "SHOEBOX_ITEM") {
        return {
            type: "FILE" as const,
            title: draggedItems.value[0].title,
            documentName: draggedItems.value[0].document.name,
            count: draggedItems.value.length
        };
    }
    return null;
}
import * as f from "react";
import { useState as b } from "react";
import { a as h, _ as S } from "./tslib.es6-CR4a2p76.js";
import { g as w } from "./index-pK5bN1zh.js";
var s = "right-scroll-bar-position", l = "width-before-scroll-bar", C = "with-scroll-bars-hidden", x = "--removed-body-scroll-bar-size";
function E(n, e) {
  return typeof n == "function" ? n(e) : n && (n.current = e), n;
}
function R(n, e) {
  var t = b(function() {
    return {
      // value
      value: n,
      // last callback
      callback: e,
      // "memoized" public interface
      facade: {
        get current() {
          return t.value;
        },
        set current(r) {
          var a = t.value;
          a !== r && (t.value = r, t.callback(r, a));
        }
      }
    };
  })[0];
  return t.callback = e, t.facade;
}
function P(n, e) {
  return R(null, function(t) {
    return n.forEach(function(r) {
      return E(r, t);
    });
  });
}
function k(n) {
  return n;
}
function N(n, e) {
  e === void 0 && (e = k);
  var t = [], r = !1, a = {
    read: function() {
      if (r)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return t.length ? t[t.length - 1] : n;
    },
    useMedium: function(c) {
      var i = e(c, r);
      return t.push(i), function() {
        t = t.filter(function(o) {
          return o !== i;
        });
      };
    },
    assignSyncMedium: function(c) {
      for (r = !0; t.length; ) {
        var i = t;
        t = [], i.forEach(c);
      }
      t = {
        push: function(o) {
          return c(o);
        },
        filter: function() {
          return t;
        }
      };
    },
    assignMedium: function(c) {
      r = !0;
      var i = [];
      if (t.length) {
        var o = t;
        t = [], o.forEach(c), i = t;
      }
      var y = function() {
        var d = i;
        i = [], d.forEach(c);
      }, g = function() {
        return Promise.resolve().then(y);
      };
      g(), t = {
        push: function(d) {
          i.push(d), g();
        },
        filter: function(d) {
          return i = i.filter(d), t;
        }
      };
    }
  };
  return a;
}
function U(n) {
  n === void 0 && (n = {});
  var e = N(null);
  return e.options = h({ async: !0, ssr: !1 }, n), e;
}
var p = function(n) {
  var e = n.sideCar, t = S(n, ["sideCar"]);
  if (!e)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var r = e.read();
  if (!r)
    throw new Error("Sidecar medium not found");
  return f.createElement(r, h({}, t));
};
p.isSideCarExport = !0;
function q(n, e) {
  return n.useMedium(e), p;
}
function T() {
  if (!document)
    return null;
  var n = document.createElement("style");
  n.type = "text/css";
  var e = w();
  return e && n.setAttribute("nonce", e), n;
}
function A(n, e) {
  n.styleSheet ? n.styleSheet.cssText = e : n.appendChild(document.createTextNode(e));
}
function I(n) {
  var e = document.head || document.getElementsByTagName("head")[0];
  e.appendChild(n);
}
var W = function() {
  var n = 0, e = null;
  return {
    add: function(t) {
      n == 0 && (e = T()) && (A(e, t), I(e)), n++;
    },
    remove: function() {
      n--, !n && e && (e.parentNode && e.parentNode.removeChild(e), e = null);
    }
  };
}, z = function() {
  var n = W();
  return function(e, t) {
    f.useEffect(function() {
      return n.add(e), function() {
        n.remove();
      };
    }, [e && t]);
  };
}, M = function() {
  var n = z(), e = function(t) {
    var r = t.styles, a = t.dynamic;
    return n(r, a), null;
  };
  return e;
}, B = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, v = function(n) {
  return parseInt(n || "", 10) || 0;
}, L = function(n) {
  var e = window.getComputedStyle(document.body), t = e[n === "padding" ? "paddingLeft" : "marginLeft"], r = e[n === "padding" ? "paddingTop" : "marginTop"], a = e[n === "padding" ? "paddingRight" : "marginRight"];
  return [v(t), v(r), v(a)];
}, j = function(n) {
  if (n === void 0 && (n = "margin"), typeof window > "u")
    return B;
  var e = L(n), t = document.documentElement.clientWidth, r = window.innerWidth;
  return {
    left: e[0],
    top: e[1],
    right: e[2],
    gap: Math.max(0, r - t + e[2] - e[0])
  };
}, G = M(), u = "data-scroll-locked", Q = function(n, e, t, r) {
  var a = n.left, c = n.top, i = n.right, o = n.gap;
  return t === void 0 && (t = "margin"), `
  .`.concat(C, ` {
   overflow: hidden `).concat(r, `;
   padding-right: `).concat(o, "px ").concat(r, `;
  }
  body[`).concat(u, `] {
    overflow: hidden `).concat(r, `;
    overscroll-behavior: contain;
    `).concat([
    e && "position: relative ".concat(r, ";"),
    t === "margin" && `
    padding-left: `.concat(a, `px;
    padding-top: `).concat(c, `px;
    padding-right: `).concat(i, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(o, "px ").concat(r, `;
    `),
    t === "padding" && "padding-right: ".concat(o, "px ").concat(r, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(s, ` {
    right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(l, ` {
    margin-right: `).concat(o, "px ").concat(r, `;
  }
  
  .`).concat(s, " .").concat(s, ` {
    right: 0 `).concat(r, `;
  }
  
  .`).concat(l, " .").concat(l, ` {
    margin-right: 0 `).concat(r, `;
  }
  
  body[`).concat(u, `] {
    `).concat(x, ": ").concat(o, `px;
  }
`);
}, m = function() {
  var n = parseInt(document.body.getAttribute(u) || "0", 10);
  return isFinite(n) ? n : 0;
}, V = function() {
  f.useEffect(function() {
    return document.body.setAttribute(u, (m() + 1).toString()), function() {
      var n = m() - 1;
      n <= 0 ? document.body.removeAttribute(u) : document.body.setAttribute(u, n.toString());
    };
  }, []);
}, D = function(n) {
  var e = n.noRelative, t = n.noImportant, r = n.gapMode, a = r === void 0 ? "margin" : r;
  V();
  var c = f.useMemo(function() {
    return j(a);
  }, [a]);
  return f.createElement(G, { styles: Q(c, !e, a, t ? "" : "!important") });
};
export {
  D as R,
  U as c,
  q as e,
  l as f,
  M as s,
  P as u,
  s as z
};

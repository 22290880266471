import { j as s } from "./jsx-runtime-B6kdoens.js";
import { T as c, O as p, C, a as f, D as x, b as k, R as O, P as g } from "./index-DXoJ8Sep.js";
import * as d from "react";
import { c as o } from "./utils-CJ9afRe1.js";
const P = O, u = c;
u.displayName = c.displayName;
const i = ({ ...a }) => /* @__PURE__ */ s.jsx(g, { ...a });
i.displayName = g.displayName;
const y = d.forwardRef(({ className: a, ...t }, e) => /* @__PURE__ */ s.jsx(
  p,
  {
    ref: e,
    className: o(
      "fixed inset-0 z-dialog bg-black-primary/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...t
  }
));
y.displayName = p.displayName;
const N = d.forwardRef(({ className: a, children: t, overlayClassName: e, withoutPortal: D, ...T }, h) => {
  const r = /* @__PURE__ */ s.jsx(y, { className: e, onWheel: (l) => l.stopPropagation(), children: /* @__PURE__ */ s.jsx(
    C,
    {
      onInteractOutside: (l) => {
        var n, m;
        (((n = l.target) == null ? void 0 : n.closest("[role='alert']")) ?? ((m = l.target) == null ? void 0 : m.closest("[data-toast='true']"))) && l.preventDefault();
      },
      ref: h,
      className: o(
        "fixed sm:max-h-[95%] left-[50%] top-[50%] z-dialog flex flex-col w-full sm:max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full",
        a
      ),
      ...T,
      children: t
    }
  ) });
  return D ? r : /* @__PURE__ */ s.jsx(i, { children: r });
});
N.displayName = "DialogContent";
const j = ({
  className: a,
  ...t
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col py-3 px-4 text-center sm:text-left",
      a
    ),
    ...t
  }
);
j.displayName = "Header";
const b = ({
  className: a,
  hideDivider: t,
  ...e
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: o(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 px-3 py-2",
      {
        "border-t": !t
      },
      a
    ),
    ...e
  }
);
b.displayName = "Footer";
const w = d.forwardRef(({ className: a, ...t }, e) => /* @__PURE__ */ s.jsx(
  f,
  {
    ref: e,
    className: o(
      "text-black- py-2 px-3.5 text-sm font-medium leading-none tracking-tight",
      a
    ),
    ...t
  }
));
w.displayName = f.displayName;
const R = d.forwardRef(({ className: a, ...t }, e) => /* @__PURE__ */ s.jsx(
  x,
  {
    ref: e,
    className: o("px-3", a),
    ...t
  }
));
R.displayName = x.displayName;
const H = Object.assign(P, {
  Content: N,
  Description: R,
  Footer: b,
  Header: j,
  Title: w,
  Trigger: u,
  Portal: i,
  Close: k
});
export {
  H as D
};

import Button from 'components/dist/atoms/Button';
import HighlightText from 'components/dist/atoms/HighlightText';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import Skeleton from 'components/dist/atoms/Skeleton';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { NextLinkComposed } from 'src/components/common/next-link-composed';

import { useSearchAutocomplete } from './search-autocomplete.state';
import { SearchAutocompleteProps } from './search-autocomplete.types';


export const SearchAutocomplete = (props: SearchAutocompleteProps) => {
    const state = useSearchAutocomplete(props);

    return <Stack items='center' justify='center' className='w-full group'>
        <Input
            onChange={state.onInputChange}
            value={state.query}
            className='text-sm'
            placeholder={state.placeholder}
            wrapClassName='w-full bg-gray-neutral-30 border border-gray-neutral-30 focus-within:bg-white focus-within:border-blue-100 rounded-full h-10 focus-within:z-splash relative' >
            <Input.Prefix>
                <Icon name="Search" width={18} height={18} strokeWidth={1.5} />
            </Input.Prefix>
            {state.query && <Input.Suffix>
                <Button
                    onClick={state.onClearSearchClick}
                    className='p-0'
                    size='sm'
                    variant='ghost' circle>
                    <Icon name="Cancel" className='text-black-primary' width={20} height={20} strokeWidth={1.5} />
                </Button>
            </Input.Suffix>}
        </Input>
        {state.query && <div className='w-full shadow rounded-b-lg rounded-t-2xl top-[56px] bg-white max-w-2xl absolute pt-4 z-tooltip hidden group-focus-within:flex px-4 pb-4'>
            <div className='divide-y flex w-full max-h-80 overflow-auto scrollbar-stable flex-col gap-2 scrollbar-stable'>
                {state.noResults && <div>No results found.</div>}
                {state.isFetching && <Stack space='md'>
                    <Skeleton className='h-12' data-testid='search-skeleton' />
                    <Skeleton className='h-12' data-testid='search-skeleton' />
                    <Skeleton className='h-12' data-testid='search-skeleton' />
                </Stack>}
                {!state.isFetching && state.results.items.map((result, index) => (<NextLinkComposed className='hover:bg-black-10 py-2' to={result.link} key={`${index}-${result.header}`}>
                    <Text className="text-left w-full" weight="medium" size="sm" variant="blue">
                        <HighlightText text={result.header} highlightText={String(state.query)} />
                    </Text>
                    <Text size='sm' className="w-full">
                        <HighlightText text={result.hitText} highlightText={String(state.query)} />
                    </Text>
                </NextLinkComposed>))}
            </div>
        </div>}
    </Stack>
};
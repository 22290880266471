import Box from '@mui/material/Box';
import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Textarea from 'components/dist/atoms/Textarea';
import Tooltip from 'components/dist/atoms/Tooltip';
import { getFileType } from 'components/dist/molecules/FileIcon';
import TextClamp from 'components/dist/molecules/TextClamp';
import UserNameText from 'components/dist/molecules/UserNameText';
import VirusScanStatus from 'components/dist/molecules/VirusScanStatus';
import React, { useState } from 'react';
import type { FormElementV2ResponseDto } from 'src/backend';
import { useUser } from 'src/hooks/use-user';
import { useGetNotesForLoanQuery, useUpdateNoteMutation } from 'src/services/noteApi';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { getFormElementPriorityColor, getFormElementPriorityLabel, getPriorityIcon } from 'src/utils/form-elements';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';

import { FormElementModifierLabels } from '../form-element-modifier-labels';
import { FormElementStatusIcon, FormElementStatusLabel } from '../form-element-status-icon';

interface FormElementFileRowStatusProps {
    formElementIcon: React.ReactNode;
    answer: FormElementV2ResponseDto['answer'];
    isLastModifiedByUserVisible?: boolean;
    modifiers: FormElementV2ResponseDto['modifiers'];
    formElementName: string;
    lastModifiedByMe?: boolean;
    storageType: FormElementV2ResponseDto['storageType'];
    sizeFormatted: FormElementV2ResponseDtoExtended['sizeFormatted'];
    priorityType: FormElementV2ResponseDto['priorityType'];
    lastModifiedByUser: FormElementV2ResponseDto['lastModifiedByUser'];
    id: FormElementV2ResponseDto['id'];
    status: FormElementV2ResponseDto['status'];
    description: string;
    loanId: string;
    showTitle: boolean;
}

const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
}

export const FormElementFileRowStatus = (props: React.PropsWithChildren<FormElementFileRowStatusProps>) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [isPreviewText, setIsPreviewText] = useState<{
        noteId: string;
        content: string;
    }>({ noteId: '', content: '' });
    const { data: notes = [] } = useGetNotesForLoanQuery({
        loanId: props.loanId,
        objectId: props.id,
        objectType: 'PACKAGE_INFO',
    }, {
        skip: !isTooltipOpen
    })
    const [updateNote] = useUpdateNoteMutation();
    const userState = useUser();
    const isLastModifiedByUserVisible = (userState.isLender || (!userState.isLender && ['CONTACT', 'BORROWER'].includes(props.lastModifiedByUser?.loggedRoleGroup)))
    const lastModifiedByMe = props.lastModifiedByUser?.id === userState.user.id

    const onOpenChange = (open: boolean) => {
        setIsTooltipOpen(open)
    }

    const onUpdateNoteClick = async () => {
        try {
            await updateNote({
                id: isPreviewText.noteId,
                content: isPreviewText.content,
            });
            setIsPreviewText({
                noteId: '',
                content: ''
            });
        } catch (error) {
            console.error(error);
        }
    }

    const isInternalForm = props.modifiers.includes('SYSTEM_FORM')

    return <>
        <Tooltip
            open={isTooltipOpen}
            delayDuration={1000}
            onOpenChange={onOpenChange}>
            <Tooltip.Trigger className='overflow-hidden' asChild>
                <div>
                    {props.children}
                </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content
                    align="start"
                    side="bottom"
                    alignOffset={0}
                    className="border-0 z-tooltip p-2 flex max-w-64 overflow-hidden flex-col rounded-md sm:w-64 gap-2"
                >
                    {props.showTitle && <Stack row space="sm" items="center">
                        <Text as="span" size="xs">
                            {props.formElementName}
                        </Text>
                    </Stack>}
                    <Stack space="sm" className='divide-y divide-gray-neutral-80' >
                        {props.storageType === 'FILE' && props.answer && <Stack row space="sm" items="center">
                            <Text as="span" size="xs" weight="medium" className="shrink-0">
                                Item Type:
                            </Text>
                            <Text size='xs' variant='secondary'>
                                {getFileType(getExtensionFromFilename(props.answer?.document?.name))}
                            </Text>
                        </Stack>}
                        {props.storageType === 'FOLDER' && <Stack row space="sm" items="center">
                            <Text as="span" size="xs" weight="medium" className="shrink-0">
                                Size:
                            </Text>
                            <Text size='xs' variant='secondary'>
                                {props.sizeFormatted}
                            </Text>
                        </Stack>}
                        {props.status !== 'OPEN' && <Stack row space="sm" items="center" className='pt-2'>
                            <Text as="span" size="xs" weight="medium" className="shrink-0">
                                Item Status
                            </Text>
                            <FormElementStatusIcon status={props.status} />
                            <FormElementStatusLabel
                                size="xs"
                                status={props.status} />
                            {isInternalForm && <Badge>Internal</Badge>}
                        </Stack>}
                        {props.status === 'REJECTED' && notes
                            .filter(note => note.noteType === 'REJECTION')
                            .map(note => <Stack key={note.id} row
                                className='overflow-hidden pt-2'>
                                <TextClamp
                                    className='text-xs text-gray-secondary'
                                    onView={userState.isLender ? () => setIsPreviewText({ content: note.content, noteId: note.id }) : undefined}
                                    onViewMore={() => setIsPreviewText({ content: note.content, noteId: note.id })}
                                    lines={2}>
                                    <Text
                                        as="div"
                                        size="xs"
                                        className="whitespace-pre-wrap break-all inline"
                                        variant="secondary">
                                        <Text as="span" size="inherit" weight="medium">Reason:</Text> {note.content}
                                    </Text>
                                </TextClamp>
                            </Stack>)}
                        {props.storageType === 'FILE' && props.description && <Stack row className='overflow-hidden pt-2'>
                            <TextClamp
                                className='text-xs text-gray-secondary'
                                onViewMore={() => setIsPreviewText({ content: props.description, noteId: '' })}
                                lines={2}>
                                <Text
                                    as="div"
                                    size="xs"
                                    className="whitespace-pre-wrap break-all"
                                    variant="secondary">
                                    <Text as="span" size="inherit" weight="medium">Description:</Text> {props.description}
                                </Text>
                            </TextClamp>
                        </Stack>}
                        {props.storageType === 'FILE' && !props.description && <Text
                            as="div"
                            size="xs"
                            className="whitespace-pre-wrap break-all"
                            variant="secondary">
                            No description
                        </Text>}
                        {isLastModifiedByUserVisible && ['REVIEWING', 'IN_PROGRESS'].includes(props.status) && <Stack row className='overflow-hidden pt-2'>
                            <Text
                                as="div"
                                size="xs"
                                className="whitespace-pre-wrap gap-1 break-all flex flex-row"
                                variant="secondary">
                                <Text as="span" size="inherit" weight="medium" className="shrink-0">
                                    {props.status === 'REVIEWING' ? "Reviewed by:" : "Last Modified by:"}
                                </Text>
                                <UserNameText
                                    size="inherit"
                                    truncate
                                    isMe={lastModifiedByMe}
                                    user={props.lastModifiedByUser} />
                            </Text>
                        </Stack>}
                        <FormElementModifierLabels modifiers={props.modifiers} />
                        <Stack row items="center" space="sm" className='pt-2'>
                            {props.answer?.document && <VirusScanStatus
                                virusScanState={props.answer.document.virusScanState}
                            />}
                            {props.storageType === 'FILE' && <Stack space='sm' items='center' row>
                                <Box
                                    className='[&>svg]:h-4 [&>svg]:aspect-square'
                                    component="span"
                                    sx={{
                                        '& svg': {
                                            color: `${getFormElementPriorityColor(props.priorityType)}.main`,
                                        }
                                    }}
                                >
                                    {getPriorityIcon(props.priorityType)}
                                </Box>
                                <Text
                                    capitalize
                                    size="xs"
                                    variant="secondary">
                                    {getFormElementPriorityLabel(props.priorityType)}
                                </Text>
                            </Stack>}
                        </Stack>
                    </Stack>
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip>
        {!!isPreviewText.content && <Dialog
            open
            onOpenChange={(open) => !open && setIsPreviewText({
                noteId: '',
                content: ''
            })}>
            <Dialog.Content
                onClick={stopPropagation}
                className='w-full sm:max-w-[573px] divide-y gap-1'>
                <Stack
                    space="sm"
                    row
                    justify='between'
                    items="center"
                    className='pt-2 px-3 pb-1'>
                    <Stack space="sm" row items="center">
                        {props.formElementIcon} <Text size="sm" weight="medium">
                            {props.formElementName}
                        </Text>
                        <FormElementStatusIcon status={props.status} />
                    </Stack>
                    <Dialog.Close asChild>
                        <Button variant="secondary"
                            className='rounded-full aspect-square w-6'
                            size="custom">
                            <Icon name="Cancel" width={24} height={24} />
                        </Button>
                    </Dialog.Close>
                </Stack>
                <div className='p-8'>
                    {(isPreviewText.noteId && userState.isLender) ? <Textarea
                        className="scrollbar-stable h-48 text-sm"
                        value={isPreviewText.content}
                        autoFocus
                        maxLength={150}
                        onInput={(e: any) => setIsPreviewText(prevState => ({
                            ...prevState,
                            content: e.target.value,
                        }))}
                        placeholder="Enter here"
                        id="rejectionReason"
                        wrapClassName="relative"
                        readOnly={!userState.isLender}
                        name="rejectionReason" >
                        <Text
                            size="xs"
                            variant={isPreviewText.content.length >= 150 ? "destructive" : "secondary"}
                            className="absolute  bg-white p-1 rounded-sm bottom-0 right-0 mr-3 mb-1">
                            {isPreviewText.content.length}/150
                        </Text>
                    </Textarea> : <Text size="sm" className='rounded-md border p-3 pb-8 pr-8 border-gray-neutral-80 break-all'>
                        {isPreviewText.content}
                    </Text>}
                </div>
                {userState.isLender && <Stack row justify='end' className='p-2'>
                    <Button
                        onClick={onUpdateNoteClick}
                        type="button"
                        size="custom" className='min-w-32 py-2 text-sm'>
                        Save
                    </Button>
                </Stack>}
            </Dialog.Content>
        </Dialog>}
    </>
}

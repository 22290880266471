import { j as e } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as S } from "react";
import "./Accordion-B1W0sq0-.js";
import "./Avatar-D2CKq5wD.js";
import "./Badge-C0bj4DFU.js";
import { B as g } from "./Button-DhTfdjdw.js";
import { D as m } from "./DropdownMenu-vsFlyIPX.js";
import { I as r } from "./Icon-47FhyXKU.js";
import "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import { P as F } from "./ProgressBar-RMvKyuSw.js";
import { c as B } from "./utils-CJ9afRe1.js";
import "./Stack-9Jnn74Nm.js";
import "./Switch-DaK2TV5Z.js";
import { T as t } from "./Text-DOFuHqUo.js";
import "./Toast-y6W489IG.js";
import "./Toggle-DH_2vm0p.js";
import { T as o } from "./Tooltip-ae2E5kx6.js";
import { C as R } from "./Checkbox.component-Yy9AVmgW.js";
import { D as L } from "./Drawer.component-CdBQnOfp.js";
import { u as O } from "./useMediaQuery-BLIs2rKV.js";
import { F as b } from "./FileIcon.component-Bg0uSTdO.js";
const U = (a) => a.isDesktop ? {
  withPortal: a.withPortal,
  align: "start",
  side: "bottom",
  alignOffset: 0
} : {}, le = S((a, v) => {
  const n = O("(min-width: 768px)"), { className: w, ...N } = a, {
    checked: c,
    isDragging: C,
    onCancelUpload: y,
    onCheckFilesFromOrToFile: M,
    onMove: s,
    onDelete: D,
    onOpenInNewTab: z,
    onMoveToMyShoeBox: u,
    onDoubleClick: h,
    onClick: x,
    onDownload: p,
    onCopy: j,
    onRename: I,
    onSendMessage: f,
    onCopyLinks: k,
    file: i,
    withPortal: W,
    onCheckChange: T,
    ...P
  } = N, l = n ? m : L;
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      "aria-checked": c,
      "data-dragged": C,
      className: B(
        "flex data-[dragged=true]:opacity-10  aria-checked:bg-black-20 px-1 gap-1 bg-black-10 hover:bg-black-20 group rounded-md transition-colors items-center",
        w
      ),
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            className: "flex flex-col flex-1 overflow-hidden h-11 py-1.5 group/icon",
            ref: v,
            ...P,
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "flex items-center gap-2 flex-1  shrink-0  justify-center", children: [
                /* @__PURE__ */ e.jsx(
                  "button",
                  {
                    className: "hidden sm:flex items-center cursor-move text-gray-neutral-100  opacity-0 h-7 group-hover:opacity-100 transition-opacity",
                    children: /* @__PURE__ */ e.jsx(r, { name: "DragHandle", width: 8, height: 14, className: "shrink-0" })
                  }
                ),
                /* @__PURE__ */ e.jsx(
                  R,
                  {
                    className: "bg-white opacity-0 group-hover/icon:opacity-100 data-active:opacity-100",
                    checked: c,
                    size: "sm",
                    "data-ui": c ? "active" : "",
                    "data-testid": "multi-select-checkbox",
                    onClick: (d) => {
                      d.shiftKey && (M(i), d.preventDefault());
                    },
                    onCheckedChange: (d) => T(d, i)
                  }
                ),
                /* @__PURE__ */ e.jsx(b, { fileName: i.documentName, className: "shrink-0" }),
                /* @__PURE__ */ e.jsx(o.Provider, { children: /* @__PURE__ */ e.jsxs(
                  o,
                  {
                    delayDuration: 1e3,
                    children: [
                      /* @__PURE__ */ e.jsx(o.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                        t,
                        {
                          select: !1,
                          tabIndex: 0,
                          role: "button",
                          onDoubleClick: () => h == null ? void 0 : h(i),
                          onClick: () => x == null ? void 0 : x(i),
                          size: "sm",
                          className: "flex-1 inline-flex items-center min-w-0",
                          children: /* @__PURE__ */ e.jsx("span", { className: "truncate", children: i.title })
                        }
                      ) }),
                      /* @__PURE__ */ e.jsx(o.Portal, { children: /* @__PURE__ */ e.jsx(o.Content, { children: i.title }) })
                    ]
                  }
                ) })
              ] }),
              i.uploading && /* @__PURE__ */ e.jsx(
                F,
                {
                  height: "h-1",
                  progressBackGroundColor: "bg-gray-neutral-35",
                  className: "ml-9 flex-1 flex",
                  showLegend: !1,
                  items: [
                    {
                      title: "",
                      value: i.uploadProgress
                    }
                  ]
                }
              )
            ]
          }
        ),
        i.uploading && /* @__PURE__ */ e.jsx(
          g,
          {
            title: "Cancel upload",
            "aria-label": "Cancel upload",
            onClick: () => y(i),
            variant: "ghost",
            className: "inline-flex items-center",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "Cancel", width: 20, height: 20 })
          }
        ),
        !i.uploading && /* @__PURE__ */ e.jsxs("div", { className: "lg:min-w-0 lg:w-0 justify-end group-hover:w-auto flex items-center overflow-hidden gap-2 shrink-0", children: [
          s && /* @__PURE__ */ e.jsx(
            g,
            {
              title: "Move file",
              "aria-label": "Move file",
              onClick: () => s(i),
              variant: "secondary",
              className: "hidden sm:inline-flex rounded-full bg-transparent hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5",
              children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 1.5, name: "FolderMoveUp", width: 17, height: 14 })
            }
          ),
          /* @__PURE__ */ e.jsxs(
            l,
            {
              modal: !n,
              children: [
                /* @__PURE__ */ e.jsx(l.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                  g,
                  {
                    title: "More options",
                    "aria-label": "More options",
                    variant: "secondary",
                    className: "rounded-full hover:bg-gray-neutral-50 w-6 h-6 focus:bg-gray-neutral-50 p-0.5 bg-transparent inline-flex",
                    children: /* @__PURE__ */ e.jsx(r, { strokeWidth: 2, name: "MoreVert", width: 20, height: 20 })
                  }
                ) }),
                /* @__PURE__ */ e.jsxs(
                  l.Content,
                  {
                    ...U({ isDesktop: n, withPortal: W }),
                    className: "z-dialog gap-2 min-w-72",
                    children: [
                      !n && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                        /* @__PURE__ */ e.jsxs(
                          "div",
                          {
                            className: "flex gap-2 h-9items-center rounded-md px-2 pt-2",
                            children: [
                              /* @__PURE__ */ e.jsx(b, { fileName: i.documentName, className: "shrink-0" }),
                              /* @__PURE__ */ e.jsx(t, { size: "sm", truncate: !0, className: "flex-1", children: i.title })
                            ]
                          }
                        ),
                        /* @__PURE__ */ e.jsx(m.Separator, {})
                      ] }),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => z(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(r, { name: "MultiWindow", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Open in New Tab" })
                          ]
                        }
                      ),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => I(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(r, { name: "Edit", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Rename" })
                          ]
                        }
                      ),
                      (s || p) && /* @__PURE__ */ e.jsx(m.Separator, {}),
                      p && /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => p(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "DownloadMenu",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Download" })
                          ]
                        }
                      ),
                      s && /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => s(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "FolderMoveUp",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Move to..." })
                          ]
                        }
                      ),
                      j && /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => j(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "Copy",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Copy to..." })
                          ]
                        }
                      ),
                      u && /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => u(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "Box",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Move to My Shoebox" })
                          ]
                        }
                      ),
                      f && /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => f(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "MessageText",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Send Message" })
                          ]
                        }
                      ),
                      k && /* @__PURE__ */ e.jsxs(
                        l.Item,
                        {
                          onSelect: () => k(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(
                              r,
                              {
                                name: "Link",
                                strokeWidth: 2,
                                width: 18,
                                height: 18
                              }
                            ),
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", children: "Copy Link" })
                          ]
                        }
                      ),
                      /* @__PURE__ */ e.jsx(m.Separator, {}),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => D(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2 text-destructive",
                          children: [
                            /* @__PURE__ */ e.jsx(r, { name: "Trash", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(t, { weight: "medium", size: "sm", variant: "inherit", children: "Remove" })
                          ]
                        }
                      )
                    ]
                  }
                )
              ]
            }
          )
        ] })
      ]
    }
  );
});
export {
  le as S
};

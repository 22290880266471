import { BasicLoanDto, BasicLoanRoleDto, BorrowUserSetDto, DeepLinkActionRequestDto, FormElementStatusType, FormElementsV2ResponseDto, FormElementUrlResponseDto, FormElementV2ResponseDto, LabelsListDto, LeadUserSetDto, LinkActionInviteToLoanResponseDto, LoanDto, LoanPhaseCategoryType, LoanRoleDto, NewLoanDTO, ObjectTypeEnum, PackageTotalItemsDto, SearchLinkType, SearchResultsDTO, SimpleLabelDto, UpdateLoanDTO } from 'src/backend';
import { elementsTree } from 'src/slices/elementv2';
import { TemplateElementTree } from 'src/types/formelement';
import { Loan, LoanDtoExtended } from 'src/types/loan';
import { transformLoan } from 'src/utils/loan/transform-loan';

import { baseApi } from './baseApi';




export interface BasicLoanRoleDtoExtended extends BasicLoanRoleDto {
    loan: string;
}

export interface BasicLoanDtoExtended extends BasicLoanDto {
    loanRoles: BasicLoanRoleDtoExtended[],
}

export const loanApi = baseApi.enhanceEndpoints({ addTagTypes: ["PackageTotalItemsDto", "SimpleLabelDto", "ContactSubRoleDto", "ContactRelationDto", "LinkActionInviteToLoanResponseDto", 'SearchResultsDTO', 'FormElementsV2ResponseDto', 'BasicLoanDto', 'LoanDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getLoansForCompanyAndUser: build.query<BasicLoanDtoExtended[], { companyId: string, userId: string }>({
            query: (data) => ({
                url: `/v1/loans/company/${data.companyId}/user/${data.userId}`,
                method: 'GET',
            }),
            providesTags: (result, error) => {
                if (error || !Array.isArray(result)) return [];
                return result.map((loan) => ({ type: 'BasicLoanDto', id: loan.id }));
            },
            transformResponse: (response: BasicLoanDto[]) => response.map(loan => ({
                ...loan,
                loanRoles: loan.loanRoles.map((role) => ({
                    ...role,
                    loan: loan.id
                }))
            })),
        }),
        getLoansForCompanyAndUsers: build.query<Record<string, BasicLoanDtoExtended[]>, { companyId: string, userIds: string[] }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                if (!_arg.userIds.length) {
                    return { data: {}, error: null };
                }
                try {
                    // get loans for each user
                    // and merge them
                    // with userId as key
                    // and loan as value
                    const { companyId, userIds } = _arg;
                    const loans: Record<string, BasicLoanDtoExtended[]> = {};
                    const promises = _arg.userIds.map(userId => fetchWithBQ({ url: `/v1/loans/company/${companyId}/user/${userId}`, method: 'GET' }));
                    const results = await Promise.all(promises);
                    const data = results.map(result => result.data as BasicLoanDtoExtended[]);
                    data.forEach((loansForUser, i) => {
                        loans[_arg.userIds[i]] = loansForUser;
                    });
                    return { data: loans, error: null };
                } catch (error) {
                    return { data: {}, error };
                }
            },
            providesTags: (result, error) => {
                if (error || !Array.isArray(result)) return [];
                return result.map((loan) => ({ type: 'BasicLoanDto', id: loan.id }));
            },
        }),
        addLender: build.mutation<LoanDtoExtended, { loanId: string, lenderUser: LeadUserSetDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/lenderUser`,
                method: 'POST',
                data: data.lenderUser,
            }),
            transformResponse: transformLoan,
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto'
            ]
        }),
        editLender: build.mutation<LoanDto, { loanId: string, lenderUser: LeadUserSetDto }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/lenderUser`,
                method: 'PUT',
                data: data.lenderUser,
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto'
            ]
        }),
        pinLoan: build.mutation<BasicLoanDto, { loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/pin`,
                method: 'PUT',
            }),
            invalidatesTags: (_, _2, { loanId }) => [{ type: 'BasicLoanDto', id: loanId },
                'BasicLoanDto']
        }),
        unPinLoan: build.mutation<BasicLoanDto, { loanId: string }>({
            query: (data) => ({
                url: `/v1/loans/${data.loanId}/unpin`,
                method: 'PUT',
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                'BasicLoanDto']
        }),
        loansViewWithTrashedElements: build.query<LoanDtoExtended[], { categories?: LoanPhaseCategoryType[]; }>({
            query: () => ({
                url: `/v1/loans/view/withtrashelements`,
                method: 'GET',
            }),
            transformResponse: (response: LoanDto[]) => {
                return response.map(transformLoan);
            },
            providesTags: ['BasicLoanDto']
        }),
        getLoansView: build.query<LoanDtoExtended[], { categories?: LoanPhaseCategoryType[]; }>({
            query: () => ({
                url: `/v1/loans/view`,
                method: 'GET',
            }),
            transformResponse: (response: LoanDto[]) => {
                return response.map(transformLoan);
            },
            providesTags: ['BasicLoanDto']
        }),
        getLoanFolders: build.query<TemplateElementTree<FormElementV2ResponseDto>[], string>({
            query: (id) => ({
                url: `/v2/loanmanager/${id}/folders`,
                method: 'GET',
            }),
            providesTags: (_result, _error, _arg) => [{ type: 'FormElementsV2ResponseDto', id: _arg }],
            transformResponse: (response: FormElementsV2ResponseDto) => {
                const elements = Object
                    .values(response.packageInfo)
                    .map((packageInfo) => ({
                        ...response['elements'][packageInfo.elementId],
                        id: packageInfo.id,
                        parentId: packageInfo.parentInfoId ?? null,
                        elementId: packageInfo.elementId,
                        childrenIds: packageInfo.childrenIds,
                        title: packageInfo.title,
                        priorityType: packageInfo.priorityType,
                    }));

                const parentId = elements.find((folder) => !folder.parentId)?.id;

                if (!elements.length || !parentId) return [];

                const tree = elementsTree<FormElementV2ResponseDto>(elements, 'id', [parentId]);
                return tree;
            }
        }),
        getLoanById: build.query<LoanDtoExtended, string>({
            query: (id) => ({
                url: `/v1/loans/${id}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, id) => [{
                type: 'LoanDto',
                id: id
            }],
            transformResponse: transformLoan,
        }),
        createLoan: build.mutation<Loan, NewLoanDTO>({
            query: (data) => ({
                url: `/v2/loanmanager/createFromTemplate`,
                method: 'POST',
                data
            }),
            invalidatesTags: (result, _2, _3) => [{ type: 'LoanDto', id: result.id }, 'BasicLoanDto', 'PackageTotalItemsDto'],
        }),
        updateLoan: build.mutation<Loan, { id: string, payload: Partial<UpdateLoanDTO> }>({
            query: (data) => ({
                url: `/v1/loans/${data.id}`,
                method: 'PUT',
                data: data.payload
            }),
            invalidatesTags: (_1, _2, { id }) => [{ type: 'LoanDto', id }, 'BasicLoanDto'],
        }),
        exportToSharepoint: build.mutation<void, { loanId: string }>({
            query: (data) => ({
                url: `/v2/sharepoint/export/${data.loanId}`,
                method: 'POST',
            }),
        }),
        getDeepLinkActionForObject: build.query<string, { forId: string, url: string, companyId?: string }>({
            query: (data) => ({
                url: `/v2/loanmanager/deepLinkActionForLoan`,
                data,
                method: 'GET',
            }),
            transformResponse: (response: FormElementUrlResponseDto) => response.url,
        }),
        createDeepLinkActionForObject: build.mutation<FormElementUrlResponseDto, DeepLinkActionRequestDto>({
            query: (data) => ({
                url: `/v2/loanmanager/createDeepLinkActionForLoan`,
                data,
                method: 'POST',
            }),
        }),
        getPendingInvites: build.query<LinkActionInviteToLoanResponseDto[], void>({
            query: () => ({
                url: `/v1/action/pendingInvites`,
                method: 'GET',
            }),
            providesTags: ['LinkActionInviteToLoanResponseDto']
        }),
        searchLoans: build.query<SearchResultsDTO, { q: string; p?: number; d?: string; c?: string; ot?: ObjectTypeEnum[]; searchType?: SearchLinkType }>({
            query: (data) => ({
                url: `/v1/search`,
                method: 'GET',
                data
            }),
            providesTags: ['SearchResultsDTO']
        }),
        editNonLenderUser: build.mutation<LoanDto, { loanId: string, userId: string, iDto: Partial<BorrowUserSetDto> }>({
            query: (data) => ({
                url: `/v1/loans/editNonLenderUser/${data.loanId}/${data.userId}`,
                method: 'POST',
                data: data.iDto,
            }),
            invalidatesTags: (_, _2, { loanId }) => [
                { type: 'BasicLoanDto', id: loanId },
                { type: 'LoanDto', id: loanId },
                'BasicLoanDto',
                'ContactSubRoleDto',
                'ContactRelationDto'
            ]
        }),
        getLabelsAvailableToMe: build.query<SimpleLabelDto[], { loanId: string }>({
            query: (data) => ({
                url: `/v1/labels/loan/${data.loanId}/availableToMe`,
                method: 'GET',
            }),
            providesTags: ['SimpleLabelDto'],
            transformResponse: (response: LabelsListDto) => response.labels,
        }),
        getTotalPackageItemsLoansLoggedUser: build.query<{
            loansMap: Record<string, PackageTotalItemsDto>,
        } & Omit<PackageTotalItemsDto, 'loanId'>, {
            status?: FormElementStatusType,
        }>({
            query: (data) => ({
                url: `/v2/loanmanager/totalPackagesLoansUser`,
                method: 'GET',
                data
            }),
            transformResponse: (response: PackageTotalItemsDto[]) => {

                return ({
                    ...response.reduce((acc, item) => {
                        acc.totalFiles += item.totalFiles;
                        acc.totalFilesHidden += item.totalFilesHidden;
                        acc.totalFolders += item.totalFolders;
                        acc.totalFoldersHidden += item.totalFoldersHidden;
                        acc.totalPackagesItems += item.totalPackagesItems;
                        acc.totalShoexboxItems += item.totalShoexboxItems;
                        return acc;
                    }, {
                        totalFiles: 0,
                        totalFilesHidden: 0,
                        totalFolders: 0,
                        totalFoldersHidden: 0,
                        totalPackagesItems: 0,
                        totalShoexboxItems: 0,
                    } as Omit<PackageTotalItemsDto, 'loanId'>),
                    loansMap: response.reduce((acc, item) => {
                        acc[item.loanId] = item;
                        return acc;
                    }, {})
                })
            },
            providesTags: ['PackageTotalItemsDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useLoansViewWithTrashedElementsQuery,
    useGetLoansForCompanyAndUsersQuery,
    useGetLabelsAvailableToMeQuery,
    useGetTotalPackageItemsLoansLoggedUserQuery,
    useEditNonLenderUserMutation,
    useSearchLoansQuery,
    useGetPendingInvitesQuery,
    useCreateDeepLinkActionForObjectMutation,
    useGetDeepLinkActionForObjectQuery,
    useLazyGetDeepLinkActionForObjectQuery,
    useGetLoansForCompanyAndUserQuery,
    usePinLoanMutation,
    useCreateLoanMutation,
    useUpdateLoanMutation,
    useUnPinLoanMutation,
    useLazyGetLoansForCompanyAndUserQuery,
    useGetLoansViewQuery,
    useGetLoanByIdQuery,
    useLazyGetLoanByIdQuery,
    useGetLoanFoldersQuery,
    useExportToSharepointMutation,
    useEditLenderMutation,
    useAddLenderMutation
} = loanApi;




interface Props {
    hexColor: string;
    isActive?: boolean;
    onColorSelected?: (color: string) => void;
}

export const ColorPalletteListItem = (props: Props) => <button
    type='button'
    onClick={() => props.onColorSelected?.(props.hexColor)}
    className="data-[active=true]:outline data-[active=true]:border-white border-2 border-transparent outline-blue-60 w-5 aspect-square rounded-full"
    data-active={props.isActive}
    style={{
        backgroundColor: props.hexColor
    }} />
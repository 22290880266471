import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { Route } from "src/constants/routing";
import { Permission } from 'src/constants/user';

import { MysherpasTooltip } from '../common/mysherps-tooltip';
import { NextLinkComposed } from '../common/next-link-composed';
import { BellNotification } from '../notifications/bell-notification';
import { SearchAutocomplete } from '../search/search-autocomplete';
import { LoanRoleGuard } from '../user/loan-role-guard';
import { AccountPopover } from './account-popover';
import { DashboardNavbarBreadcrumbs } from './dashboard-navbar-breadcrumbs';

interface DashboardNavbarProps extends AppBarProps {
  onToggle: () => void;
  sidebarOpen?: boolean;
  showGoBackButton?: boolean;
  noSearch?: boolean;
  noSideBar?: boolean;
  noBreadcrumb?: boolean;
  customLeft?: `${string}px`
}

const DashboardNavbarRoot = styled(AppBar)(({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none'
}));


export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onToggle, customLeft = '256px', showGoBackButton, noBreadcrumb, noSearch, noSideBar, sidebarOpen, ...other } = props;
  const router = useRouter();
  const handleGoBackClick = () => {
    // if we have a referrer, go back to it
    // otherwise go back to home page
    if (document.referrer) {
      router.back();
    } else {
      router.push({
        pathname: Route.HOME
      });
    }
  };

  const isLoanLink = ([Route.SINGLE_LOAN, Route.SINGLE_LOAN_MESSAGES, Route.SINGLE_LOAN_TASKS, Route.SINGLE_LOAN_TASKS_TYPE] as string[]).includes(router.pathname)
  const isLoanExplorerActive = router.pathname.startsWith('/explorer');
  return <DashboardNavbarRoot
    className="border-b border-gray-200 bg-white print:hidden"
    sx={(theme) => ({
      left: {
        lg: noSideBar ? '0px' : customLeft,
      },
      width: {
        lg: noSideBar ? '100%' : `calc(100% - ${customLeft})`,
      },
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      ...((!sidebarOpen && !noSideBar) && {
        width: {
          lg: 'calc(100% - 58px)',
        },
        left: {
          lg: "58px"
        },
        transition: theme.transitions.create(['left', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      })
    })}
    {...other}
  >
    <Stack
      row
      items='center'
      space="sm"
      className='h-[54px] min-h-[54px] left-0 p-2  print:hidden'
    >
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button
            className="text-primary hover:text-white bg-transparent mr-1 px-1 py-1 rounded-sm xl:hidden"
            onClick={onToggle}
            data-testid="sidebar-toggle-trigger"
            size="custom">
            <Icon name="Menu" width={20} height={20} strokeWidth={2} />
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <Text size="sm">
            {open ? 'Close' : 'Open'} Menu
          </Text>
        </Tooltip.Content>
      </Tooltip>
      {showGoBackButton &&
        <Button
          onClick={handleGoBackClick}
          variant='ghost'>
          <Icon name="NavArrowLeft" width={20} height={20} />
        </Button>}
      {!noBreadcrumb && <DashboardNavbarBreadcrumbs />}
      <Stack className='flex-1' space="lg" row items="center">
        <Stack className='flex-1' justify='center' row>
          {(!noSearch && !isLoanLink) && <LoanRoleGuard
            permission={Permission.Search}>
            <div className='max-w-md w-full relative'>
              <SearchAutocomplete />
            </div>
          </LoanRoleGuard>}
        </Stack>
        <MysherpasTooltip title={`${isLoanExplorerActive ? "Close" : "Open"} Loan Explorer`}>
          <Button
            size='sm'
            className='w-8 aspect-square'
            variant='outline'
            asChild>
            <NextLinkComposed
              className={isLoanExplorerActive ? "bg-blue-100 text-white border-blue-100" : ""}
              to={{
                pathname: isLoanExplorerActive ? Route.HOME : Route.LOAN_EXPLORER_LOANS
              }}
            >
              <Icon name="ArchiveDrawer" width={20} height={20} />
            </NextLinkComposed>
          </Button>
        </MysherpasTooltip>
        <BellNotification />
        <AccountPopover />
      </Stack>
    </Stack>
  </DashboardNavbarRoot>
};

import Accordion from "components/dist/atoms/Accordion"
import Badge from "components/dist/atoms/Badge"
import Button from "components/dist/atoms/Button"
import Icon from "components/dist/atoms/Icon"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import FileIcon from "components/dist/molecules/FileIcon"
import { useEffect, useLayoutEffect, useState } from "react"
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip"
import { getString } from "src/i18n/labels"
import { getFileNameWithoutExtension } from "src/utils/file/get-file-name-without-extension"
import { getFilePathFolders } from "src/utils/file/get-file-path-folders"
import { getExtensionFromFilename } from "src/utils/get-extension-from-filename"
import { classNames } from "src/utils/tailwind-class-names"

export type UploadStatus = 'uploading' | 'success' | 'error'

export interface ToastFile {
    status: UploadStatus,
    file: File,
    uniqueId: string,
    reason: string,
    abortController: AbortController
}

export type ToastCreatingElement = {
    id: string,
    title: string,
    status: UploadStatus
}

type UploadingToastProps = {
    elements?: ToastCreatingElement[]
    files: ToastFile[],
    locations: string[],
    onCancelAll: (files: ToastFile[]) => void
}

export const UploadingToast = (props: UploadingToastProps) => {
    const [locations, setLocations] = useState<string[]>([])
    const [elements, setElements] = useState<ToastCreatingElement[]>([])
    const [maxVisibleFiles, setMaxVisibleFiles] = useState(5)
    const [files, setFiles] = useState<ToastFile[]>([])

    useLayoutEffect(() => {
        // append new files to the list
        // and update existing files
        const newFiles = props.files.filter(file => !files.some(f => f.uniqueId === file.uniqueId))
        const updatedFiles = files.map(file => {
            const newFile = props.files.find(f => f.uniqueId === file.uniqueId)
            if (newFile) {
                return newFile
            }
            return file
        })

        setFiles([
            ...newFiles,
            ...updatedFiles
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.files])

    useEffect(() => {
        // if we have new elements, add them to existing elements
        // and update the elements state
        if (props.elements?.length > 0) {
            setElements(prevState => {
                const newElements = props.elements?.filter(element => !prevState.some(e => e.id === element.id))
                const updatedElements = prevState.map(element => {
                    const newElement = props.elements?.find(e => e.id === element.id)
                    if (newElement) {
                        return newElement
                    }
                    return element
                })
                return [
                    ...newElements,
                    ...updatedElements
                ]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.elements])

    useEffect(() => {
        // if we have new locations, add them existing locations 
        // and update the locations state
        if (props.locations.length > 0) {
            setLocations(prevState => {
                const newLocations = props.locations.filter(location => !prevState.includes(location))
                return [
                    ...locations,
                    ...newLocations
                ]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.locations])

    const onCancelClick = (file: ToastFile, fileIndex: number) => {
        file.abortController.abort("single")
        setFiles(prevState => prevState.filter((_, previousIndex) => previousIndex !== fileIndex))
    }

    const errorFilesCount = files.filter(file => file.status === 'error' && file.reason !== getString('uploadCanceled')).length
    const cancelFilesCount = files.filter(file => file.status === 'error' && file.reason === getString('uploadCanceled')).length
    const groupedFiles = getTopLevelFolderOrFiles(files);
    const visibleFiles = groupedFiles.filter((_, index) => index < maxVisibleFiles);
    const uploadingFilesCount = groupedFiles.filter(file => file.status === 'uploading').length
    const uploadedFilesCount = groupedFiles.filter(file => file.status === 'success').length

    const locationLabel = getLocationsLabel(locations)
    const hasElementsSuccess = elements.some(element => element.status === 'success')
    const hasElementsUploading = elements.some(element => element.status === 'uploading')
    const creatingElementsCount = elements.length || 0
    const errorElementsCount = elements.filter(element => element.status === 'error').length
    const uploadedElementsCount = elements.filter(element => element.status === 'success').length

    const onCancelAllClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (uploadingFilesCount > 0) {
            props.onCancelAll(files.filter(file => file.status === 'uploading'))
        }
    }
    return <div
        role="alert"
        aria-label="Uploading Toast"
        className="flex flex-col gap-2">
        <Accordion
            type="multiple"
            defaultValue={["uploading", "creating"]}>
            {elements.length > 0 && <Accordion.Item value="creating">
                <div className="flex gap-2 w-full items-center">
                    <Accordion.Trigger
                        className="py-0"
                        asChild
                        onClick={(e) => e.stopPropagation()}>
                        <div className="flex gap-2 flex-1 justify-start py-0 items-center">
                            <Text size="sm">
                                {hasElementsUploading && `Creating ${creatingElementsCount} ${creatingElementsCount === 1 ? "Item" : "Items"}`}
                                {hasElementsSuccess && !hasElementsUploading && `${uploadedElementsCount} ${uploadedElementsCount === 1 ? "Item" : "Items"} Created`}
                                {hasElementsSuccess && !hasElementsUploading && errorElementsCount > 0 && ` ${errorElementsCount} ${errorElementsCount === 1 ? "Item" : "Items"} Error`}
                            </Text>
                        </div>
                    </Accordion.Trigger>
                    <Button
                        size="custom"
                        variant="ghost"
                        className="items-center flex rounded-md text-black-dark"
                    >
                        <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                    </Button>
                </div>
                <Accordion.Content onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col gap-3 pt-2">
                        {elements.map((element) => {
                            return <div
                                className="flex gap-2 items-center h-8"
                                key={element.id}>
                                {element.status === 'success' && <Icon name="Check" width={14} height={14} strokeWidth={2} className="bg-green-flowkit text-white rounded-full" />}
                                {element.status === 'error' && <Icon name="Cancel" width={14} height={14} className="bg-destructive text-white rounded-full" />}
                                {element.status === 'uploading' && <div className='w-3.5 aspect-square border-2 border-t-transparent animate-spin border-green-flowkit rounded-full' />}
                                <FileIcon
                                    size="small"
                                    className="shrink-0"
                                    fileName={element.title} />
                                <Stack row items='center' space='sm' className="overflow-hidden flex-1">
                                    <Text
                                        size="xs"
                                        as="div"
                                        variant={element.status === 'uploading' ? 'secondary' : 'default'}
                                        truncate>
                                        {element.title}
                                    </Text>
                                    {element.status === 'error' && <MysherpasTooltip asChild title={<Stack space='sm'>
                                        <Stack row space='xs'>
                                            <Icon name="Cancel" width={14} height={14} className="bg-destructive text-white rounded-full mr-1" />
                                            <Text size='xs'>
                                                Error Creating {element.title}
                                            </Text>
                                        </Stack>
                                    </Stack>}>
                                        <button>
                                            <Icon strokeWidth={1.5} name="InfoEmpty" width={14} height={14} />
                                        </button>
                                    </MysherpasTooltip>}
                                </Stack>
                            </div>
                        })}
                    </div>
                </Accordion.Content>
            </Accordion.Item>}
            {groupedFiles.length > 0 && <Accordion.Item
                disabled={elements.length > 0}
                value="uploading">
                <div className="flex gap-2 w-full items-center">
                    <Accordion.Trigger
                        disabled={elements.length > 0}
                        className={classNames("py-0", {
                            'pt-4': elements.length > 0
                        })}
                        asChild
                        onClick={(e) => e.stopPropagation()}>
                        <div className="flex gap-2 flex-1 justify-start py-0 items-center">
                            {/* {props.status === 'uploading' && <div className='w-5 aspect-square border-2 border-t-transparent animate-spin border-green-flowkit rounded-full' />} */}
                            {/* {props.status === 'success' && <Icon name="Check" width={20} height={20} className="bg-green-flowkit text-white rounded-full" />} */}
                            <Text size="sm">
                                {uploadingFilesCount > 0 && `Uploading ${uploadingFilesCount} items ${locationLabel}`}
                                {uploadedFilesCount > 0 && !uploadingFilesCount && `${uploadedFilesCount} ${uploadedFilesCount === 1 ? "Upload" : "Uploads"} Complete`}
                                {(errorFilesCount + cancelFilesCount) > 0 && uploadedFilesCount > 0 && '.'}
                                {!uploadingFilesCount && errorFilesCount > 0 && ` ${errorFilesCount} ${errorFilesCount === 1 ? "Upload" : "Uploads"} Error`}
                                {!uploadingFilesCount && cancelFilesCount > 0 && ` ${cancelFilesCount} ${cancelFilesCount === 1 ? "Upload" : "Uploads"} Canceled`}
                            </Text>
                        </div>
                    </Accordion.Trigger>
                    {elements.length === 0 && <Button
                        size="custom"
                        variant="ghost"
                        className="items-center flex rounded-md text-black-dark"
                    >
                        <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                    </Button>}
                </div>
                <Accordion.Content onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col gap-3 pt-2">
                        <div className="flex flex-col gap-3 max-h-52 overflow-auto scrollbar-stable">
                            {visibleFiles.map((file, fileIndex) => {
                                const displayFileName = getExtensionFromFilename(file.file.name) === 'folder' ? getFileNameWithoutExtension(file.file.name) : file.file.name;
                                const childFiles = files.filter(f => getFileTopLevelFolder(f.file).startsWith(displayFileName));
                                const successChildFilesCount = childFiles.filter(f => f.status === 'success').length;
                                return <div
                                    className="flex gap-2 items-center h-8 shrink-0"
                                    key={`${file.file.lastModified}-${file.file.name}-${file.file.size}-${fileIndex}`}>
                                    {file.status === 'success' && <Icon name="Check" strokeWidth={2} width={14} height={14} className="bg-green-flowkit text-white rounded-full" />}
                                    {file.status === 'error' && <Icon name="Cancel" width={14} height={14} className="bg-destructive text-white rounded-full" />}
                                    {file.status === 'uploading' && <div className='w-3.5 aspect-square border-2 border-t-transparent animate-spin border-green-flowkit rounded-full' />}
                                    <FileIcon
                                        size="small"
                                        className="shrink-0"
                                        fileName={file.file.name} />
                                    <Stack row items='center' space='sm' className="overflow-hidden flex-1">
                                        <Text
                                            size="xs"
                                            as="div"
                                            variant={file.status === 'uploading' ? 'secondary' : 'default'}
                                            truncate>
                                            {displayFileName}
                                        </Text>
                                        {childFiles.length > 0 && <Badge variant="lightSecondary" className="text-[10px] h-auto px-1 py-0 text-black-primary">{successChildFilesCount} of {childFiles.length}</Badge>}
                                        {file.status === 'error' && <MysherpasTooltip asChild title={<Stack space='sm'>
                                            <Stack row space='xs'>
                                                <Icon name="Cancel" width={14} height={14} className="bg-destructive text-white rounded-full mr-1" />
                                                <Text size='xs'>
                                                    Error uploading {displayFileName}
                                                </Text>
                                            </Stack>
                                            <Text size='xs'>{file.reason}</Text>
                                        </Stack>}>
                                            <button>
                                                <Icon strokeWidth={1.5} name="InfoEmpty" width={14} height={14} />
                                            </button>
                                        </MysherpasTooltip>}
                                    </Stack>
                                    {file.status === 'uploading' && <Button
                                        size="custom"
                                        variant="ghost"
                                        className="h-8 rounded-md text-black-dark shrink-0"
                                        onClick={() => onCancelClick(file, fileIndex)}>
                                        <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                                    </Button>}
                                </div>
                            })}
                        </div>
                        {groupedFiles.length > 5 && <Stack row items='center' space='none'>
                            <Badge
                                variant="lightSecondary"
                                circle
                                size="md">
                                {groupedFiles.length}
                            </Badge>
                            <Button className="underline" variant='link'
                                onClick={() => setMaxVisibleFiles(prevState => prevState === 5 ? files.length : 5)}
                                size='sm'>
                                View {visibleFiles.length < groupedFiles.length ? 'All' : 'Less'}
                            </Button>
                        </Stack>}
                    </div>
                </Accordion.Content>
            </Accordion.Item>}
        </Accordion>
        {uploadingFilesCount > 0 && <div>
            <Button
                aria-label="Cancel All"
                onClick={onCancelAllClick}
                className="bg-white"
                variant="outline" size="sm">
                Cancel Upload
            </Button>
        </div>}
    </div>
}


const getFileTopLevelFolder = (file: File): string => {
    const filePathFolders = getFilePathFolders(file);
    return filePathFolders.length > 0 ? filePathFolders[0] : '';
}

const getTopLevelFolderOrFiles = (files: ToastFile[]): ToastFile[] => {
    // Create a map to track the top-level folders and files
    const topLevelFilesMap = new Map<string, ToastFile>();

    // Process each file path
    files.forEach(file => {
        // Split the file path by "/"

        const topLevelFolder = getFileTopLevelFolder(file.file);

        if (!topLevelFolder) {
            // If there is no parent folder, it's just a file, so we add it directly
            topLevelFilesMap.set(file.file.name, file);
            // Otherwise, we add the top-level folder as a "file"
            // Check if the folder is not already added
        } else if (!topLevelFilesMap.has(topLevelFolder)) {
            // Create a new `File` object to represent the folder
            const folderFile: ToastFile = {
                status: file.status,
                reason: file.reason,
                file: new File([], `${topLevelFolder}.folder`),
                uniqueId: topLevelFolder,
                abortController: file.abortController,
            }
            topLevelFilesMap.set(topLevelFolder, folderFile);
        }
    });

    // Convert the map to an array and return the list of top-level files
    return Array.from(topLevelFilesMap.values());
}

const getLocationsLabel = (locations: string[]): string => {
    if (locations.length === 0) {
        return '';
    } else if (locations.length === 1) {
        return `to ${locations[0]}`;
    }
    return `to ${locations.length} locations`
}
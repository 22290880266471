import * as a from "react";
import { useEffect as j } from "react";
import { _ as z, a as B, b as _ } from "./tslib.es6-CR4a2p76.js";
import { c as q, u as Q, f as p, z as J, s as K, R as O, e as U } from "./component-ByV7h_MU.js";
let X = 0;
function be() {
  j(() => {
    var e, r;
    const n = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", (e = n[0]) !== null && e !== void 0 ? e : W()), document.body.insertAdjacentElement("beforeend", (r = n[1]) !== null && r !== void 0 ? r : W()), X++, () => {
      X === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach(
        (o) => o.remove()
      ), X--;
    };
  }, []);
}
function W() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.cssText = "outline: none; opacity: 0; position: fixed; pointer-events: none", e;
}
var F = q(), Y = function() {
}, A = a.forwardRef(function(e, r) {
  var n = a.useRef(null), o = a.useState({
    onScrollCapture: Y,
    onWheelCapture: Y,
    onTouchMoveCapture: Y
  }), u = o[0], s = o[1], v = e.forwardProps, c = e.children, b = e.className, g = e.removeScrollBar, S = e.enabled, C = e.shards, E = e.sideCar, y = e.noIsolation, w = e.inert, t = e.allowPinchZoom, l = e.as, d = l === void 0 ? "div" : l, h = z(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as"]), f = E, m = Q([n, r]), i = B(B({}, h), u);
  return a.createElement(
    a.Fragment,
    null,
    S && a.createElement(f, { sideCar: F, removeScrollBar: g, shards: C, noIsolation: y, inert: w, setCallbacks: s, allowPinchZoom: !!t, lockRef: n }),
    v ? a.cloneElement(a.Children.only(c), B(B({}, i), { ref: m })) : a.createElement(d, B({}, i, { className: b, ref: m }), c)
  );
});
A.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
A.classNames = {
  fullWidth: p,
  zeroRight: J
};
var x = !1;
if (typeof window < "u")
  try {
    var T = Object.defineProperty({}, "passive", {
      get: function() {
        return x = !0, !0;
      }
    });
    window.addEventListener("test", T, T), window.removeEventListener("test", T, T);
  } catch {
    x = !1;
  }
var R = x ? { passive: !1 } : !1, ee = function(e) {
  return e.tagName === "TEXTAREA";
}, V = function(e, r) {
  var n = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    n[r] !== "hidden" && // contains scroll inside self
    !(n.overflowY === n.overflowX && !ee(e) && n[r] === "visible")
  );
}, re = function(e) {
  return V(e, "overflowY");
}, te = function(e) {
  return V(e, "overflowX");
}, I = function(e, r) {
  var n = r;
  do {
    typeof ShadowRoot < "u" && n instanceof ShadowRoot && (n = n.host);
    var o = Z(e, n);
    if (o) {
      var u = G(e, n), s = u[1], v = u[2];
      if (s > v)
        return !0;
    }
    n = n.parentNode;
  } while (n && n !== document.body);
  return !1;
}, ae = function(e) {
  var r = e.scrollTop, n = e.scrollHeight, o = e.clientHeight;
  return [
    r,
    n,
    o
  ];
}, ne = function(e) {
  var r = e.scrollLeft, n = e.scrollWidth, o = e.clientWidth;
  return [
    r,
    n,
    o
  ];
}, Z = function(e, r) {
  return e === "v" ? re(r) : te(r);
}, G = function(e, r) {
  return e === "v" ? ae(r) : ne(r);
}, le = function(e, r) {
  return e === "h" && r === "rtl" ? -1 : 1;
}, oe = function(e, r, n, o, u) {
  var s = le(e, window.getComputedStyle(r).direction), v = s * o, c = n.target, b = r.contains(c), g = !1, S = v > 0, C = 0, E = 0;
  do {
    var y = G(e, c), w = y[0], t = y[1], l = y[2], d = t - l - s * w;
    (w || d) && Z(e, c) && (C += d, E += w), c = c.parentNode;
  } while (
    // portaled content
    !b && c !== document.body || // self content
    b && (r.contains(c) || r === c)
  );
  return (S && (C === 0 || !u) || !S && (E === 0 || !u)) && (g = !0), g;
}, $ = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, D = function(e) {
  return [e.deltaX, e.deltaY];
}, H = function(e) {
  return e && "current" in e ? e.current : e;
}, ce = function(e, r) {
  return e[0] === r[0] && e[1] === r[1];
}, ie = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, ue = 0, k = [];
function se(e) {
  var r = a.useRef([]), n = a.useRef([0, 0]), o = a.useRef(), u = a.useState(ue++)[0], s = a.useState(function() {
    return K();
  })[0], v = a.useRef(e);
  a.useEffect(function() {
    v.current = e;
  }, [e]), a.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(u));
      var t = _([e.lockRef.current], (e.shards || []).map(H), !0).filter(Boolean);
      return t.forEach(function(l) {
        return l.classList.add("allow-interactivity-".concat(u));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(u)), t.forEach(function(l) {
          return l.classList.remove("allow-interactivity-".concat(u));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var c = a.useCallback(function(t, l) {
    if ("touches" in t && t.touches.length === 2)
      return !v.current.allowPinchZoom;
    var d = $(t), h = n.current, f = "deltaX" in t ? t.deltaX : h[0] - d[0], m = "deltaY" in t ? t.deltaY : h[1] - d[1], i, N = t.target, P = Math.abs(f) > Math.abs(m) ? "h" : "v";
    if ("touches" in t && P === "h" && N.type === "range")
      return !1;
    var L = I(P, N);
    if (!L)
      return !0;
    if (L ? i = P : (i = P === "v" ? "h" : "v", L = I(P, N)), !L)
      return !1;
    if (!o.current && "changedTouches" in t && (f || m) && (o.current = i), !i)
      return !0;
    var M = o.current || i;
    return oe(M, l, t, M === "h" ? f : m, !0);
  }, []), b = a.useCallback(function(t) {
    var l = t;
    if (!(!k.length || k[k.length - 1] !== s)) {
      var d = "deltaY" in l ? D(l) : $(l), h = r.current.filter(function(i) {
        return i.name === l.type && i.target === l.target && ce(i.delta, d);
      })[0];
      if (h && h.should) {
        l.cancelable && l.preventDefault();
        return;
      }
      if (!h) {
        var f = (v.current.shards || []).map(H).filter(Boolean).filter(function(i) {
          return i.contains(l.target);
        }), m = f.length > 0 ? c(l, f[0]) : !v.current.noIsolation;
        m && l.cancelable && l.preventDefault();
      }
    }
  }, []), g = a.useCallback(function(t, l, d, h) {
    var f = { name: t, delta: l, target: d, should: h };
    r.current.push(f), setTimeout(function() {
      r.current = r.current.filter(function(m) {
        return m !== f;
      });
    }, 1);
  }, []), S = a.useCallback(function(t) {
    n.current = $(t), o.current = void 0;
  }, []), C = a.useCallback(function(t) {
    g(t.type, D(t), t.target, c(t, e.lockRef.current));
  }, []), E = a.useCallback(function(t) {
    g(t.type, $(t), t.target, c(t, e.lockRef.current));
  }, []);
  a.useEffect(function() {
    return k.push(s), e.setCallbacks({
      onScrollCapture: C,
      onWheelCapture: C,
      onTouchMoveCapture: E
    }), document.addEventListener("wheel", b, R), document.addEventListener("touchmove", b, R), document.addEventListener("touchstart", S, R), function() {
      k = k.filter(function(t) {
        return t !== s;
      }), document.removeEventListener("wheel", b, R), document.removeEventListener("touchmove", b, R), document.removeEventListener("touchstart", S, R);
    };
  }, []);
  var y = e.removeScrollBar, w = e.inert;
  return a.createElement(
    a.Fragment,
    null,
    w ? a.createElement(s, { styles: ie(u) }) : null,
    y ? a.createElement(O, { gapMode: "margin" }) : null
  );
}
const de = U(F, se);
var fe = a.forwardRef(function(e, r) {
  return a.createElement(A, B({}, e, { ref: r, sideCar: de }));
});
fe.classNames = A.classNames;
export {
  be as $,
  fe as R
};

import { j as s } from "./jsx-runtime-B6kdoens.js";
import { _ as l, a as D, b as E } from "./index-CWsRuimy.js";
import * as i from "react";
import { forwardRef as d, createElement as r, useRef as A } from "react";
import { $ as h } from "./index-Cp-Nu9_j.js";
import { $ as u, a as z, b as F, c as M, d as H, e as k, f as _, g as I, h as L, i as V } from "./index-BPITpeKS.js";
import { b as q } from "./index-B5chcmfZ.js";
import { b as N } from "./Button-DhTfdjdw.js";
import { c as f } from "./utils-CJ9afRe1.js";
const B = "AlertDialog", [G, Re] = h(B, [
  u
]), n = u(), J = (e) => {
  const { __scopeAlertDialog: a, ...t } = e, o = n(a);
  return /* @__PURE__ */ r(I, l({}, o, t, {
    modal: !0
  }));
}, K = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, c = n(t);
  return /* @__PURE__ */ r(L, l({}, c, o, {
    ref: a
  }));
}), Q = (e) => {
  const { __scopeAlertDialog: a, ...t } = e, o = n(a);
  return /* @__PURE__ */ r(V, l({}, o, t));
}, U = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, c = n(t);
  return /* @__PURE__ */ r(z, l({}, c, o, {
    ref: a
  }));
}), v = "AlertDialogContent", [W, X] = G(v), Y = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, children: o, ...c } = e, p = n(t), b = A(null), T = D(a, b), m = A(null);
  return /* @__PURE__ */ r(F, {
    contentName: v,
    titleName: Z,
    docsSlug: "alert-dialog"
  }, /* @__PURE__ */ r(W, {
    scope: t,
    cancelRef: m
  }, /* @__PURE__ */ r(M, l({
    role: "alertdialog"
  }, p, c, {
    ref: T,
    onOpenAutoFocus: q(c.onOpenAutoFocus, ($) => {
      var g;
      $.preventDefault(), (g = m.current) === null || g === void 0 || g.focus({
        preventScroll: !0
      });
    }),
    onPointerDownOutside: ($) => $.preventDefault(),
    onInteractOutside: ($) => $.preventDefault()
  }), /* @__PURE__ */ r(E, null, o), !1)));
}), Z = "AlertDialogTitle", ee = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, c = n(t);
  return /* @__PURE__ */ r(H, l({}, c, o, {
    ref: a
  }));
}), ae = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, c = n(t);
  return /* @__PURE__ */ r(k, l({}, c, o, {
    ref: a
  }));
}), te = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, c = n(t);
  return /* @__PURE__ */ r(_, l({}, c, o, {
    ref: a
  }));
}), oe = "AlertDialogCancel", ce = /* @__PURE__ */ d((e, a) => {
  const { __scopeAlertDialog: t, ...o } = e, { cancelRef: c } = X(oe, t), p = n(t), b = D(a, c);
  return /* @__PURE__ */ r(_, l({}, p, o, {
    ref: b
  }));
}), re = J, se = K, le = Q, ne = U, fe = Y, de = te, ie = ce, $e = ee, pe = ae, be = re, ge = se, R = le, x = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsx(
  ne,
  {
    className: f(
      "fixed inset-0 z-dialog bg-black-primary/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      e
    ),
    ...a,
    ref: t
  }
));
x.displayName = "AlertDialogOverlay";
const y = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsxs(R, { children: [
  /* @__PURE__ */ s.jsx(x, { className: "z-full-screen" }),
  /* @__PURE__ */ s.jsx(
    fe,
    {
      ref: t,
      className: f(
        "fixed left-[50%] top-[50%] z-full-screen flex flex-col w-full sm:max-w-sm lg:max-w-lg h-screen sm:h-auto sm:max-h-[95%] translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
        e
      ),
      ...a
    }
  )
] }));
y.displayName = "AlertDialogContent";
const C = ({
  className: e,
  ...a
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: f(
      "flex flex-col space-y-2 sm:text-left",
      e
    ),
    ...a
  }
);
C.displayName = "AlertDialogHeader";
const j = ({
  className: e,
  ...a
}) => /* @__PURE__ */ s.jsx(
  "div",
  {
    className: f(
      "flex flex-row justify-end space-x-2 px-3 py-2",
      e
    ),
    ...a
  }
);
j.displayName = "AlertDialogFooter";
const w = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsx(
  $e,
  {
    ref: t,
    className: f("", e),
    ...a
  }
));
w.displayName = "AlertDialogTitle";
const P = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsx(
  pe,
  {
    ref: t,
    className: f("text-sm text-muted-foreground", e),
    ...a
  }
));
P.displayName = "AlertDialogDescription";
const S = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsx(
  de,
  {
    ref: t,
    className: f(N(), e),
    ...a
  }
));
S.displayName = "AlertDialogAction";
const O = i.forwardRef(({ className: e, ...a }, t) => /* @__PURE__ */ s.jsx(
  ie,
  {
    ref: t,
    className: f(
      N({ variant: "outline" }),
      e
    ),
    ...a
  }
));
O.displayName = "AlertDialogCancel";
const ye = Object.assign(be, {
  Portal: R,
  Overlay: x,
  Trigger: ge,
  Content: y,
  Header: C,
  Footer: j,
  Title: w,
  Description: P,
  Action: S,
  Cancel: O
});
export {
  ye as A
};

import { j as a } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as F, useCallback as w, useImperativeHandle as C } from "react";
import { u as H } from "./index-ym6RKKkt.js";
import { D as y } from "./DropHere.component-Dkz1hoyk.js";
import { c as z } from "./utils-CJ9afRe1.js";
const N = {
  "image/jpg": [".jpg"],
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
  "image/bmp": [".bmp"],
  "image/tiff": [".tiff"],
  "image/heic": [".heic"],
  "video/mp4": [".mp4"],
  "video/avi": [".avi"],
  "video/mov": [".mov"],
  "video/flv": [".flv"],
  "video/mkv": [".mkv"],
  "video/m4v": [".m4v"],
  "video/mpeg": [".mpeg"],
  "application/pdf": [".pdf"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".odt",
    ".docx",
    ".doc",
    ".dotx",
    ".dot"
  ],
  // 'application/msword': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.ms-excel': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".ppsx",
    ".pptm",
    ".pot",
    ".potm",
    ".ppsx",
    ".pps",
    ".pptx",
    ".ppt"
  ],
  // 'application/vnd.ms-powerpoint': ['.ppt', '.pptx', '.potx', '.pot'],
  "application/vnd.ms-excel": [".xls", ".xlsb", ".xlt", ".xltm", ".xltx", ".xlsx", ".csv", ".xlsm"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xls", ".xlsb", ".xlt", ".xltm", ".xltx", ".xlsx", ".csv", ".xlsm"],
  "application/zip": [".zip"],
  "application/x-rar-compressed": [".rar"],
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "application/json": [".json"],
  "application/xml": [".xml"],
  "image/*": [".png", ".jpg", ".jpeg", ".gif", ".webp", ".bmp", ".tiff", ".heic"],
  "video/*": [".mp4", ".avi", ".mov", ".flv", ".mkv", ".m4v", ".mpeg"]
  // 'application/pdf': ['.pdf'],
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/msword': ['.docx', '.doc', '.dotx', '.dot'],
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.ms-excel': ['.xlsx', '.xls', '.xltx', '.xlt', 'xlsm'],
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx', '.ppt', '.potx', '.pot'],
  // 'application/vnd.ms-powerpoint': ['.ppt', '.pptx', '.potx', '.pot'],
  // 'application/zip': ['.zip'],
  // 'application/x-rar-compressed': ['.rar'],
  // 'text/plain': ['.txt'],
  // 'text/csv': ['.csv'],
  // 'application/json': ['.json'],
  // 'application/xml': ['.xml'],
}, m = F((c, d) => {
  const { onFilesDrop: p, accept: n, showDropHere: g, activeClassName: f, as: v, hideDropHere: x, multiple: u = !1, error: r = !1, disabled: o = !1, children: b, uploadFolders: s, ...l } = c, D = v ?? "div", j = w(
    (i, k, t) => {
      t == null || t.stopPropagation(), p(i, k.map((A) => A.file));
    },
    [p]
  ), e = H({
    // noDragEventsBubbling: true,
    accept: n ?? N,
    onDrop: j,
    multiple: u,
    disabled: o,
    useFsAccessApi: !s
  }), h = (i) => {
    i.preventDefault(), i.target instanceof HTMLElement && i.target.getAttribute("data-upload-trigger") && e.open();
  };
  return C(d, () => ({
    openFilePicker: () => e.open()
  }), [e]), /* @__PURE__ */ a.jsxs(
    D,
    {
      "aria-invalid": r,
      "aria-disabled": o,
      "aria-label": "Drop files here",
      "data-ui": e.isDragActive ? "active" : "",
      ...l,
      className: z(
        "relative group/drop",
        l.className,
        e.isDragActive && f,
        {
          "is-dragover [&_.is-drag-active]:flex": e.isDragActive,
          "border-destructive": r
        }
      ),
      ...e.getRootProps(),
      onClick: o ? void 0 : h,
      children: [
        /* @__PURE__ */ a.jsx(
          "input",
          {
            role: "button",
            "data-testid": "upload-file-input",
            ...e.getInputProps(),
            ...s && { webkitdirectory: "", mozdirectory: "", directory: "" },
            "aria-label": "Upload file"
          }
        ),
        g && /* @__PURE__ */ a.jsx(y, { hideText: x, active: e.isDragActive }),
        b
      ]
    }
  );
});
m.displayName = "FilesDropContainer";
m.DropTargetAttributes = { "data-upload-trigger": "true" };
export {
  m as F,
  N as a
};

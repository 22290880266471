import { useDroppable } from '@dnd-kit/core';
import { GlobalShoeBoxProps } from 'components/dist/organisms/GlobalShoeBox';
import { ShoeBoxFile } from 'components/dist/organisms/GlobalShoeBox/GlobalShoeBox.types';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useSubscription } from "react-stomp-hooks";
import { toast as reactToast } from 'react-toastify';
import { api } from 'src/api'
import { KeyStorage } from 'src/constants/key-storage';
import { RoleTypeLabel } from 'src/constants/loan';
import { Route } from "src/constants/routing";
import { QUERY_PARAM_USER_SHOEBOX_OPEN, QUERY_SHOEBOX_ITEM_PREVIEW_ID } from 'src/constants/shoebox';
import { UPLOADING_TOAST_ID } from 'src/constants/toast';
import { useUser } from 'src/hooks/use-user';
import { useGetCompanyEmployeesQuery, useGetCompanyShoeBoxesQuery, useGetLendersForBorrowerQuery, useNotifyCompanyShoeBoxMutation } from 'src/services/companyApi';
import { useLazyGetDocumentWithDownloadUrlQuery } from 'src/services/documentApi';
import { useCreateShoeBoxItemMutation, useDeleteShoeBoxItemMutation, useGetMyShoeBoxItemsQuery, useMarkShoeboxItemForDeleteMutation, useUpdateShoeBoxItemMutation } from 'src/services/lenderShoeBoxApi';
import { useGetLoansViewQuery, useLazyGetLoansForCompanyAndUserQuery } from 'src/services/loanApi';
import { useGetKeyPreferenceQuery, useSetKeyPreferenceMutation } from 'src/services/userApi';
import { getLoan } from 'src/slices/loan';
import { getLoanShoeBoxItems } from 'src/slices/shoebox';
import { useDispatch } from 'src/store';
import { FileUploading } from 'src/types/formelement';
import { isZipFile } from 'src/utils/file/is-zip-file';
import { uploadFileToDocumentId } from 'src/utils/form-element/upload-file-to-ducoment-id';
import { getInitials } from 'src/utils/get-initials';
import { filterActivePhaseCategory } from 'src/utils/loan/filter-active-phase-category';
import { toast } from 'src/utils/toast';
import { getUserDisplayName } from 'src/utils/user/get-user-display-name';
import { getFoldersAndFiles } from 'src/utils/zip';

import { transformedLoanDtoToLoan, useShoeBoxItemViewerContext } from '../shoebox-item-viewer/shoebox-item-viewer.context';
import { mapShoeboxItemsToFolder } from './dashboard-sidebar-shoebox.utils';
import { UndoToast } from './UndoToast';
import { ToastFile, UploadingToast } from './UploadingToast';

const TOAST_DURATION = 10_000;

const AbortReason = {
    "SINGLE": "single",
    "ALL": "all"
}

type UploadStatus = "CANCEL" | "ERROR" | "SUCCESS"

const generateUniqueUUID = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const useDashboardSidebarShoebox = () => {
    const router = useRouter();
    const userState = useUser();
    const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
    const [uploadingState, setUploadingState] = useState<Record<string, FileUploading>>({});
    const [usersLoansMap, setUsersLoansMap] = useState<Record<string, GlobalShoeBoxProps['folders'][0]['loans']>>({});
    const [usersAvatarMap, setUsersAvatarMap] = useState<Record<string, string>>({});
    const shoeboxItemViewerContext = useShoeBoxItemViewerContext();
    const [getDocumentWithDownloadUrl] = useLazyGetDocumentWithDownloadUrlQuery();
    const { data: selectedTeamUserIds } = useGetKeyPreferenceQuery<{ data: string[] | undefined }>({
        key: KeyStorage.LenderShoeBoxTeamFilterList
    });
    const [notifyCompanyShoebox] = useNotifyCompanyShoeBoxMutation();
    const [setKeyPreference] = useSetKeyPreferenceMutation();
    const isDialogOpen = !!router.query[QUERY_PARAM_USER_SHOEBOX_OPEN];

    const { data: companyShoeBoxesData, refetch: refetchCompanyShoeBoxes } = useGetCompanyShoeBoxesQuery({
        companyId: userState.company?.id,
        status: 'ACTIVE'
    }, {
        skip: !userState.company?.id || !isDialogOpen,
        pollingInterval: 60_000
    });
    const { data: companyEmployees = [] } = useGetCompanyEmployeesQuery({
        companyId: userState.company?.id
    }, {
        skip: !userState.company?.id || !isDialogOpen
    });
    const { data: lendersData = [] } = useGetLendersForBorrowerQuery(null, {
        skip: userState.isLender
    });

    // only get archive category for lenders
    const { data: loans = [], isLoading: isLoadingLoans } = useGetLoansViewQuery({
        categories: [
            'LEAD',
            'ORIGINATION',
            'PORTFOLIO',
            'ARCHIVE',
        ]
    })

    const { currentData: [...loggedInUserShoeboxItems] = [], isLoading: isLoadingFiles, refetch: refetchMyShoeBox } = useGetMyShoeBoxItemsQuery(null,
        {
            skip: !userState?.user?.id,
            refetchOnMountOrArgChange: true
        });

    const dispatch = useDispatch();
    const [createShoeBoxItem] = useCreateShoeBoxItemMutation();
    const [getLoansForCompanyUser] = useLazyGetLoansForCompanyAndUserQuery();
    const [updateShoeBoxItem] = useUpdateShoeBoxItemMutation();
    const [markShoeboxItemForDelete] = useMarkShoeboxItemForDeleteMutation();

    const refreshLoan = async (loanId: string) => {
        dispatch(getLoan(loanId));
        dispatch(getLoanShoeBoxItems(loanId));
    }

    const handleBulkCancelUploadingFile = async (files: ToastFile[]) => {
        files.forEach((file) => {
            file.abortController.abort(AbortReason.ALL);
        })
        toast({
            type: 'error',
            duration: TOAST_DURATION,
            content: `Upload canceled for ${files.length} items`
        })
    }

    const onAddFilesToShoeBox = async (files: File[], rejectedFiles: File[]) => {
        const otherFiles = files.filter((file) => !isZipFile(file));
        // get zip files to extract and get files from
        const zipFilePromises = files.filter(isZipFile)
            .map(async (file) => {
                try {
                    const { files: extractedFiles } = await getFoldersAndFiles(file);
                    return extractedFiles;
                } catch (error) {
                    toast({
                        type: 'error',
                        duration: TOAST_DURATION,
                        content: `Failed to extract ${file.name} will be uploaded as zip.`
                    })
                    return [file];
                }
            });
        const zipFiles = await Promise.all(zipFilePromises);

        const allFiles = [
            ...otherFiles,
            ...zipFiles.flat()
        ];

        const filesWithAbortControllers = allFiles
            .map((file) => {
                const abortController = new AbortController();
                const uniqueId = generateUniqueUUID();

                setUploadingState(prevState => ({
                    ...prevState,
                    [uniqueId]: {
                        id: uniqueId,
                        name: file.name,
                        progress: 0,
                        abortController: abortController
                    }
                }))

                return ({
                    uniqueId,
                    reason: '',
                    abortController: abortController,
                    file,
                    status: 'uploading' as const,
                })
            })
        if (filesWithAbortControllers.length > 0) {
            const toastArgs = {
                autoClose: false as const,
                closeButton: false,
                toastId: UPLOADING_TOAST_ID,
                updateId: UPLOADING_TOAST_ID,
                className: 'rounded-md bg-black-10',
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                type: 'default' as const
            }

            if (!reactToast.isActive(UPLOADING_TOAST_ID)) {
                reactToast(<UploadingToast
                    onCancelAll={handleBulkCancelUploadingFile}
                    locations={['my shoebox']}
                    files={filesWithAbortControllers}
                />,
                    toastArgs)
            } else {
                reactToast.update(UPLOADING_TOAST_ID, {
                    render: <UploadingToast
                        locations={['my shoebox']}
                        onCancelAll={handleBulkCancelUploadingFile}
                        files={filesWithAbortControllers}
                    />,
                    ...toastArgs
                })
            }
        }
        // upload file and get document id back
        const createDocumentPromises = filesWithAbortControllers
            .map<Promise<UploadStatus>>(async (file, fileIndex) => {
                let uploadStatus: UploadStatus = "SUCCESS";

                const result = {
                    documentId: '',
                    file: file.file
                }
                try {
                    const uploadResult = await uploadFileToDocumentId({
                        signal: file.abortController.signal,
                        file: file.file,
                        pdfTronKey: '',
                        progress: (percent) => setUploadingState(prevState => ({
                            ...prevState,
                            [file.uniqueId]: {
                                ...prevState[file.uniqueId],
                                progress: percent
                            }

                        }))

                    })
                    await createShoeBoxItem({
                        documentId: uploadResult.documentId,
                        id: null,
                        loanId: null,
                        shoeboxOwnerId: userState.user.id,
                        shoeboxType: 'PERSONAL' as const,
                        title: uploadResult.file.name,
                        uploadedById: userState.user.id,
                        userId: userState.user.id,
                        newFile: null,
                        documentType: null
                    })

                    result.documentId = uploadResult.documentId
                    result.file = uploadResult.file
                    uploadStatus = "SUCCESS";
                } catch (error) {
                    if (error?.config?.signal?.reason !== AbortReason.ALL) {
                        toast({
                            type: 'error',
                            duration: TOAST_DURATION,
                            content: "Upload canceled"
                        });
                    }
                    if ([AbortReason.SINGLE, AbortReason.ALL].includes(error?.config?.signal?.reason)) {
                        uploadStatus = "CANCEL";
                    } else {
                        uploadStatus = "ERROR";
                    }

                } finally {
                    setUploadingState(prevState => ({
                        ...prevState,
                        [file.uniqueId]: undefined
                    }))
                }

                reactToast.update(UPLOADING_TOAST_ID, {
                    render: <UploadingToast
                        locations={['my shoebox']}
                        onCancelAll={handleBulkCancelUploadingFile}
                        files={filesWithAbortControllers.map((file, index) => ({
                            ...file,
                            ...(index === fileIndex ? {
                                status: uploadStatus === 'SUCCESS' ? 'success' : 'error'
                            } : {})
                        }))}
                    />,
                    type: 'default'
                })
                return uploadStatus;

            });

        const documentResults = await Promise.all(createDocumentPromises);
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        reactToast.update(UPLOADING_TOAST_ID, {
            render: <UploadingToast
                locations={['my shoebox']}
                onCancelAll={handleBulkCancelUploadingFile}
                files={filesWithAbortControllers.map((file, index) => ({
                    ...file,
                    status: documentResults[index] === 'SUCCESS' ? 'success' : 'error'
                }))
                }
            />,
            type: 'default'
        })
        const erroredUploads = documentResults.filter((result) => result === 'ERROR').length;
        if (erroredUploads) {
            toast({
                type: 'error',
                duration: TOAST_DURATION,
                content: `${erroredUploads} items failed to upload`
            })
        }
        if (rejectedFiles.length) {
            setRejectedFiles(rejectedFiles);
        }
    }

    const handleCancelUploadingFile = async (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        uploadingState[file.id]?.abortController.abort(AbortReason.SINGLE);
        setUploadingState(prevState => ({
            ...prevState,
            [file.id]: undefined
        }))
    }

    const handleRestoreDeletedShoeBoxItems = async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const [firstFile] = files;
        const updatePromises = files.map((file) => createShoeBoxItem({
            documentId: file.documentId,
            id: null,
            loanId: null,
            shoeboxOwnerId: file.ownerId,
            shoeboxType: 'PERSONAL' as const,
            title: file.title,
            uploadedById: file.uploadedById,
            userId: userState.user.id,
            newFile: null,
            documentType: null
        }))
        await Promise.all(updatePromises);
        if (firstFile.loanId) {
            refreshLoan(firstFile.loanId)
        }
        reactToast.success(`${files.length} items restored successfully`,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })
    }

    const onMoveShoeBoxFilesToLoan = async (loan: GlobalShoeBoxProps['loans'][0], userId: string, files: GlobalShoeBoxProps['folders'][0]['files']) => {
        const updatePromises = files.map((file) => updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: loan.id,
            shoeboxOwnerId: userId,
            shoeboxType: 'LOAN' as const,
            title: file.title,
            uploadedById: userState.user.id,
            userId: userId,
            newFile: null,
            documentType: null
        }))
        await Promise.all(updatePromises);
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        refreshLoan(loan.id)
        reactToast.success(<UndoToast
            onUndo={async () => shoeboxItemViewerContext.onMoveItemsBackToShoeBox(files)}
            message={`${files.length} items added to ${loan.shortCode} ${loan.projectName} successfully`} />,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })
    }

    const onGoToLoan = (loanId: string) => {
        router.push({
            pathname: Route.SINGLE_LOAN,
            query: {
                loanId
            }
        })
    }

    const onGoToLoanCreatePage = () => {
        router.push({
            pathname: Route.CREATE_LOAN
        })
    }

    const onAddMeToLoan = async (loanId: string) => {
        await api.addLender(loanId, {
            appUser: userState.user.id,
            contactVisibleToBorrowers: false,
            contactVisibleToLenders: false,
            canAcceptFiles: null,
            leadBorrower: null,
            leadLender: null,
            borrowerType: null,
            newToLoan: true,
            role: userState.user.loggedCompanyRole,
            visibleToBorrower: null,
            contactRelation: null,
        })
    }

    const onDeleteShoeBoxFiles = async (files: GlobalShoeBoxProps['folders'][0]['files']) => {
        if (files.length === 0) {
            toast({
                type: 'error',
                duration: TOAST_DURATION,
                content: `Please select at least one item to delete`
            })
            return;
        }
        const [firstFile] = files;
        const isBulk = files.length > 1;
        const deletePromises = files.map((file) => markShoeboxItemForDelete({
            id: file.id
        }))
        await Promise.all(deletePromises);
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        if (firstFile.loanId) {
            refreshLoan(firstFile.loanId)
        }
        const toastContentPrefix = isBulk ? `${files.length} items` : firstFile.title;
        reactToast.success(<UndoToast
            onUndo={async () => handleRestoreDeletedShoeBoxItems(files)}
            message={`${toastContentPrefix} deleted successfully`} />,
            {
                hideProgressBar: true,
                autoClose: TOAST_DURATION,
                closeOnClick: true,
                position: 'bottom-right',
                pauseOnHover: true,
            })
    }

    const onOpenShoeBoxFileInNewTab = async (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        const url = new URL(window.location.origin);
        url.searchParams.set(QUERY_SHOEBOX_ITEM_PREVIEW_ID, file.id);
        // open the url in a new tab
        window.open(url.toString(), '_blank');
    }

    const onRenameShoeBoxFile = async (file: GlobalShoeBoxProps['folders'][0]['files'][0], newTitle: string) => {
        await updateShoeBoxItem({
            documentId: file.documentId,
            id: file.id,
            loanId: null,
            shoeboxOwnerId: file.ownerId,
            shoeboxType: file.shoeboxType,
            title: newTitle,
            uploadedById: file.uploadedById,
            userId: userState.user.id,
            newFile: null,
            documentType: null
        })
        notifyCompanyShoebox({
            companyId: userState.company?.id
        })
        // refresh loan if the file is in a loan
        if (file.loanId) {
            refreshLoan(file.loanId)
        }
        toast({
            type: 'success',
            content: `${file.title} renamed to ${newTitle} successfully`,
            duration: TOAST_DURATION
        })
    }

    const onOpenFileDoubleClick = async (file: GlobalShoeBoxProps['folders'][0]['files'][0]) => {
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                [QUERY_SHOEBOX_ITEM_PREVIEW_ID]: file.id
            }
        })
    }

    const onDialogOpenChange = (open: boolean) => {
        const query = {
            ...router.query
        }
        if (!open) {
            delete query[QUERY_PARAM_USER_SHOEBOX_OPEN]
        } else {
            query[QUERY_PARAM_USER_SHOEBOX_OPEN] = "1"
        }
        router.push({
            pathname: router.pathname,
            query
        })
    }

    const onTeamFilterChange = (userIds: string[]) => {
        setKeyPreference({
            key: KeyStorage.LenderShoeBoxTeamFilterList,
            value: userIds
        })
    }
    const defaultFolder: GlobalShoeBoxProps['folders'][0] = useMemo(() => ({
        key: userState.user.id,
        name: userState.user.emailAddress,
        senderUserId: userState.user.id,
        ownerUserId: userState.user.id,
        isSubFolder: false,
        filesCount: 0,
        senderGivenName: userState.user.givenName,
        senderName: userState.user.emailAddress,
        senderEmailAddress: userState.user.emailAddress,
        owner: "ME",
        files: [],
        messages: [],
        subFolders: [],
        loans: usersLoansMap[userState.user.id] ?? []
    }), [userState.user.id, userState.user.emailAddress, userState.user.givenName, usersLoansMap])

    const folders = useMemo(() => {
        return mapShoeboxItemsToFolder(
            loggedInUserShoeboxItems,
            defaultFolder,
            usersLoansMap,
            companyShoeBoxesData,
            userState.user.id
        );
    }, [companyShoeBoxesData, loggedInUserShoeboxItems, defaultFolder, usersLoansMap, userState.user.id])

    const uploadingStateFiles = Object.values(uploadingState)
        .filter(uploadState => typeof uploadState !== 'undefined')

    if (uploadingStateFiles.length) {
        folders[userState.user.id] = {
            ...folders[userState.user.id],
            filesCount: folders[userState.user.id]?.filesCount + uploadingStateFiles.length,
            files: [
                ...uploadingStateFiles
                    .map(uploadState => ({
                        id: uploadState.id,
                        title: uploadState.name,
                        documentName: uploadState.name,
                        documentId: null,
                        uploading: true,
                        ownerId: userState.user.id,
                        uploadedById: userState.user.id,
                        uploadProgress: uploadState.progress,
                        loanId: null,
                        shoeboxType: 'PERSONAL' as const
                    }) as ShoeBoxFile),
                ...folders[userState.user.id]?.files ?? [],
            ]
        }
    }

    const secureUploadPageLink = userState.info?.secureUploadPageEnabled
        ? userState.info.secureUploadPageUrl
        : '';

    const userIdsWithShoeboxItems = loggedInUserShoeboxItems
        .map((item) => item.uploadedBy.id);
    const teamShoeboxUserIds = Object.keys(companyShoeBoxesData ?? {})
        .filter((userId) => userId !== userState.user.id)
        .map((userId) => userId);

    const loanUserIds = [
        ...userIdsWithShoeboxItems,
        ...teamShoeboxUserIds
    ]

    const avatarsAlreadyLoaded = Object.keys(usersAvatarMap).length > 0;
    useEffect(function getEmployeesLogos() {
        if (!companyEmployees.length || !isDialogOpen || avatarsAlreadyLoaded) {
            return;
        }
        try {
            const promises = companyEmployees
                .filter(employee => employee.avatarDocId)
                .map(async (employee) => {
                    // if we don't have an avatar doc id return
                    if (!employee.avatarDocId) {
                        return {
                            id: employee.id,
                            logoUrl: ""
                        }
                    }
                    const { data } = await getDocumentWithDownloadUrl({
                        id: employee.avatarDocId,

                    });
                    return {
                        id: employee.id,
                        logoUrl: data?.downloadUrl
                    }
                })
            Promise.all(promises)
                .then((results) => {
                    const employeeLogos = results.reduce((all, next) => {
                        return {
                            ...all,
                            [next.id]: next.logoUrl
                        }
                    }, {})
                    setUsersAvatarMap(prevState => ({
                        ...prevState,
                        ...employeeLogos
                    }))
                })
        } catch {
            console.error('failed getting employees logos')
        }
    }, [companyEmployees, getDocumentWithDownloadUrl, isDialogOpen, avatarsAlreadyLoaded])

    useEffect(function getShoeboxUsersActiveLoans() {
        // if we don't have a company return
        if (!userState.company?.id || !isDialogOpen || loanUserIds.length === 0) {
            return;
        }
        (async () => {
            try {
                const promises = loanUserIds.map(async userId => {
                    const loans = await getLoansForCompanyUser({
                        userId,
                        companyId: userState.company?.id
                    })
                    return {
                        userId,
                        loans: loans.data
                    }
                })
                const results = await Promise.all(promises);
                const usersLoansMap = results.reduce((all, next) => {
                    return {
                        ...all,
                        [next.userId]: next.loans
                    }
                }, {})

                setUsersLoansMap(usersLoansMap);
            } catch {
                console.error('failed getting users loans')
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanUserIds.length, userState.company?.id, isDialogOpen])

    const subscriptions = []
    if (userState.company?.id) {
        subscriptions.push(`/topic/shoebox/${userState.company?.id}`)
    }
    if (lendersData.length) {
        subscriptions.push(...lendersData.map((lender) => `/topic/shoebox/${lender.id}`))
    }

    useSubscription(subscriptions, () => {
        refetchCompanyShoeBoxes();
        refetchMyShoeBox();
    });

    const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
        id: "MY_SHOEBOX",
        data: {
            type: "MY_SHOEBOX",
        },
    });

    const filteredLoans = loans.filter(filterActivePhaseCategory);
    const transformedLoans = transformedLoanDtoToLoan(filteredLoans, userState.user.id);
    const team: GlobalShoeBoxProps['team'] = companyEmployees
        .filter(employee => employee.id !== userState.user.id)
        .map((employee) => ({
            avatarUrl: usersAvatarMap[employee.id] ?? "",
            id: employee.id,
            initials: getInitials(getUserDisplayName(employee)),
            name: getUserDisplayName(employee),
            email: employee.emailAddress,
            filesCount: companyShoeBoxesData?.[employee.id]?.length ?? 0,
            folders: []
        }));

    const foldersList = Object.values(folders);


    return {
        folders: foldersList,
        onAddFilesToShoeBox,
        onCancelUploadingFile: handleCancelUploadingFile,
        onMoveShoeBoxFilesToLoan,
        onGoToLoan,
        onGoToLoanCreatePage,
        onAddMeToLoan,
        onDeleteShoeBoxFiles,
        onOpenShoeBoxFileInNewTab,
        onRenameShoeBoxFile,
        onDialogOpenChange,
        onOpenFileDoubleClick,
        onTeamFilterChange,
        setDroppableNodeRef,
        onMoveLoanShoeboxItemsToMyShoebox: shoeboxItemViewerContext.onMoveItemsBackToShoeBox,
        isDroppableNodeOver: isOver,
        selectedTeamUserIds: !!selectedTeamUserIds ? selectedTeamUserIds : [userState.user.id],
        rejectedFiles,
        team,
        secureUploadPageLink,
        isDialogOpen,
        loans: transformedLoans,
        isLoadingLoans,
        hideLoanFilter: userState.isBorrower,
        me: {
            avatarUrl: usersAvatarMap[userState.user.id] ?? "",
            id: userState.user.id,
            filesCount: loggedInUserShoeboxItems.length,
            givenName: userState.user.givenName,
            initials: getInitials(getUserDisplayName(userState.user)),
            name: getUserDisplayName(userState.user),
            email: userState.user.emailAddress,
            role: RoleTypeLabel(userState.user.loggedCompanyRole)
        },
        totalItems: foldersList.reduce((all, next) => all + next.filesCount, 0),
        isLoadingShoeBoxFiles: isLoadingFiles
    } as const;
}


import { j as A } from "./jsx-runtime-B6kdoens.js";
import { a as K, _ as I, $ as Tt } from "./index-CWsRuimy.js";
import * as de from "react";
import { forwardRef as O, useContext as It, useState as T, useEffect as B, createElement as m, useRef as W, createContext as Ot, useCallback as U, Fragment as Ne, useMemo as Nt } from "react";
import Dt, { createPortal as Ge } from "react-dom";
import { $ as Fe } from "./index-CxNOZmcj.js";
import { b as L, $ as $e, a as He } from "./index-B5chcmfZ.js";
import { $ as At } from "./index-CXJObptD.js";
import { $ as qe, a as ee } from "./index-Cp-Nu9_j.js";
import { $ as Rt } from "./index-U5S6G_il.js";
import { $ as F, a as Lt } from "./index-B--ic68G.js";
import { $ as Mt } from "./index-Bt4B6WE4.js";
import { $ as Ft, R as Ht } from "./Combination-q8N9GQYQ.js";
import { $ as De } from "./index-rKUeoTl7.js";
import { u as kt, a as Bt, o as Wt, s as Vt, l as Ut, f as Kt, b as jt, c as zt, h as Yt } from "./floating-ui.react-dom-vb4skoDG.js";
import { $ as On } from "./index-Bixjd1-8.js";
import { $ as Xt } from "./index-DaaF0TLE.js";
import { $ as Gt } from "./index-CX8l_p9w.js";
import { a as qt } from "./index-DJ_R-fw-.js";
import { h as Zt } from "./index-pK5bN1zh.js";
import { c as Jt } from "./index-BV2fynjD.js";
import { c as le } from "./utils-CJ9afRe1.js";
import { I as Se } from "./Icon-47FhyXKU.js";
const Ie = "dismissableLayer.update", Qt = "dismissableLayer.pointerDownOutside", eo = "dismissableLayer.focusOutside";
let ke;
const to = /* @__PURE__ */ Ot({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), oo = /* @__PURE__ */ O((t, o) => {
  var e;
  const { disableOutsidePointerEvents: n = !1, onEscapeKeyDown: a, onPointerDownOutside: i, onFocusOutside: c, onInteractOutside: r, onDismiss: s, ...l } = t, p = It(to), [v, C] = T(null), g = (e = v == null ? void 0 : v.ownerDocument) !== null && e !== void 0 ? e : globalThis == null ? void 0 : globalThis.document, [, h] = T({}), u = K(
    o,
    (w) => C(w)
  ), x = Array.from(p.layers), [f] = [
    ...p.layersWithOutsidePointerEventsDisabled
  ].slice(-1), d = x.indexOf(f), $ = v ? x.indexOf(v) : -1, P = p.layersWithOutsidePointerEventsDisabled.size > 0, D = $ >= d, te = no((w) => {
    const H = w.target, q = [
      ...p.branches
    ].some(
      (j) => j.contains(H)
    );
    !D || q || (i == null || i(w), r == null || r(w), w.defaultPrevented || s == null || s());
  }, g), N = ro((w) => {
    const H = w.target;
    [
      ...p.branches
    ].some(
      (j) => j.contains(H)
    ) || (c == null || c(w), r == null || r(w), w.defaultPrevented || s == null || s());
  }, g);
  return Mt((w) => {
    $ === p.layers.size - 1 && (a == null || a(w), !w.defaultPrevented && s && (w.preventDefault(), s()));
  }, g), B(() => {
    if (v)
      return n && (p.layersWithOutsidePointerEventsDisabled.size === 0 && (ke = g.body.style.pointerEvents, g.body.style.pointerEvents = "none"), p.layersWithOutsidePointerEventsDisabled.add(v)), p.layers.add(v), Be(), () => {
        n && p.layersWithOutsidePointerEventsDisabled.size === 1 && (g.body.style.pointerEvents = ke);
      };
  }, [
    v,
    g,
    n,
    p
  ]), B(() => () => {
    v && (p.layers.delete(v), p.layersWithOutsidePointerEventsDisabled.delete(v), Be());
  }, [
    v,
    p
  ]), B(() => {
    const w = () => h({});
    return document.addEventListener(Ie, w), () => document.removeEventListener(Ie, w);
  }, []), /* @__PURE__ */ m(F.div, I({}, l, {
    ref: u,
    style: {
      pointerEvents: P ? D ? "auto" : "none" : void 0,
      ...t.style
    },
    onFocusCapture: L(t.onFocusCapture, N.onFocusCapture),
    onBlurCapture: L(t.onBlurCapture, N.onBlurCapture),
    onPointerDownCapture: L(t.onPointerDownCapture, te.onPointerDownCapture)
  }));
});
function no(t, o = globalThis == null ? void 0 : globalThis.document) {
  const e = $e(t), n = W(!1), a = W(() => {
  });
  return B(() => {
    const i = (r) => {
      if (r.target && !n.current) {
        let l = function() {
          Ze(Qt, e, s, {
            discrete: !0
          });
        };
        const s = {
          originalEvent: r
        };
        r.pointerType === "touch" ? (o.removeEventListener("click", a.current), a.current = l, o.addEventListener("click", a.current, {
          once: !0
        })) : l();
      } else
        o.removeEventListener("click", a.current);
      n.current = !1;
    }, c = window.setTimeout(() => {
      o.addEventListener("pointerdown", i);
    }, 0);
    return () => {
      window.clearTimeout(c), o.removeEventListener("pointerdown", i), o.removeEventListener("click", a.current);
    };
  }, [
    o,
    e
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => n.current = !0
  };
}
function ro(t, o = globalThis == null ? void 0 : globalThis.document) {
  const e = $e(t), n = W(!1);
  return B(() => {
    const a = (i) => {
      i.target && !n.current && Ze(eo, e, {
        originalEvent: i
      }, {
        discrete: !1
      });
    };
    return o.addEventListener("focusin", a), () => o.removeEventListener("focusin", a);
  }, [
    o,
    e
  ]), {
    onFocusCapture: () => n.current = !0,
    onBlurCapture: () => n.current = !1
  };
}
function Be() {
  const t = new CustomEvent(Ie);
  document.dispatchEvent(t);
}
function Ze(t, o, e, { discrete: n }) {
  const a = e.originalEvent.target, i = new CustomEvent(t, {
    bubbles: !1,
    cancelable: !0,
    detail: e
  });
  o && a.addEventListener(t, o, {
    once: !0
  }), n ? Lt(a, i) : a.dispatchEvent(i);
}
const _e = "focusScope.autoFocusOnMount", Te = "focusScope.autoFocusOnUnmount", We = {
  bubbles: !1,
  cancelable: !0
}, ao = /* @__PURE__ */ O((t, o) => {
  const { loop: e = !1, trapped: n = !1, onMountAutoFocus: a, onUnmountAutoFocus: i, ...c } = t, [r, s] = T(null), l = $e(a), p = $e(i), v = W(null), C = K(
    o,
    (u) => s(u)
  ), g = W({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  B(() => {
    if (n) {
      let u = function($) {
        if (g.paused || !r) return;
        const P = $.target;
        r.contains(P) ? v.current = P : ie(v.current, {
          select: !0
        });
      }, x = function($) {
        if (g.paused || !r) return;
        const P = $.relatedTarget;
        P !== null && (r.contains(P) || ie(v.current, {
          select: !0
        }));
      }, f = function($) {
        if (document.activeElement === document.body)
          for (const D of $) D.removedNodes.length > 0 && ie(r);
      };
      document.addEventListener("focusin", u), document.addEventListener("focusout", x);
      const d = new MutationObserver(f);
      return r && d.observe(r, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", u), document.removeEventListener("focusout", x), d.disconnect();
      };
    }
  }, [
    n,
    r,
    g.paused
  ]), B(() => {
    if (r) {
      Ue.add(g);
      const u = document.activeElement;
      if (!r.contains(u)) {
        const f = new CustomEvent(_e, We);
        r.addEventListener(_e, l), r.dispatchEvent(f), f.defaultPrevented || (co(fo(Je(r)), {
          select: !0
        }), document.activeElement === u && ie(r));
      }
      return () => {
        r.removeEventListener(_e, l), setTimeout(() => {
          const f = new CustomEvent(Te, We);
          r.addEventListener(Te, p), r.dispatchEvent(f), f.defaultPrevented || ie(u ?? document.body, {
            select: !0
          }), r.removeEventListener(Te, p), Ue.remove(g);
        }, 0);
      };
    }
  }, [
    r,
    l,
    p,
    g
  ]);
  const h = U((u) => {
    if (!e && !n || g.paused) return;
    const x = u.key === "Tab" && !u.altKey && !u.ctrlKey && !u.metaKey, f = document.activeElement;
    if (x && f) {
      const d = u.currentTarget, [$, P] = so(d);
      $ && P ? !u.shiftKey && f === P ? (u.preventDefault(), e && ie($, {
        select: !0
      })) : u.shiftKey && f === $ && (u.preventDefault(), e && ie(P, {
        select: !0
      })) : f === d && u.preventDefault();
    }
  }, [
    e,
    n,
    g.paused
  ]);
  return /* @__PURE__ */ m(F.div, I({
    tabIndex: -1
  }, c, {
    ref: C,
    onKeyDown: h
  }));
});
function co(t, { select: o = !1 } = {}) {
  const e = document.activeElement;
  for (const n of t)
    if (ie(n, {
      select: o
    }), document.activeElement !== e) return;
}
function so(t) {
  const o = Je(t), e = Ve(o, t), n = Ve(o.reverse(), t);
  return [
    e,
    n
  ];
}
function Je(t) {
  const o = [], e = document.createTreeWalker(t, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (n) => {
      const a = n.tagName === "INPUT" && n.type === "hidden";
      return n.disabled || n.hidden || a ? NodeFilter.FILTER_SKIP : n.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; e.nextNode(); ) o.push(e.currentNode);
  return o;
}
function Ve(t, o) {
  for (const e of t)
    if (!io(e, {
      upTo: o
    })) return e;
}
function io(t, { upTo: o }) {
  if (getComputedStyle(t).visibility === "hidden") return !0;
  for (; t; ) {
    if (o !== void 0 && t === o) return !1;
    if (getComputedStyle(t).display === "none") return !0;
    t = t.parentElement;
  }
  return !1;
}
function lo(t) {
  return t instanceof HTMLInputElement && "select" in t;
}
function ie(t, { select: o = !1 } = {}) {
  if (t && t.focus) {
    const e = document.activeElement;
    t.focus({
      preventScroll: !0
    }), t !== e && lo(t) && o && t.select();
  }
}
const Ue = uo();
function uo() {
  let t = [];
  return {
    add(o) {
      const e = t[0];
      o !== e && (e == null || e.pause()), t = Ke(t, o), t.unshift(o);
    },
    remove(o) {
      var e;
      t = Ke(t, o), (e = t[0]) === null || e === void 0 || e.resume();
    }
  };
}
function Ke(t, o) {
  const e = [
    ...t
  ], n = e.indexOf(o);
  return n !== -1 && e.splice(n, 1), e;
}
function fo(t) {
  return t.filter(
    (o) => o.tagName !== "A"
  );
}
const Qe = "Popper", [et, tt] = qe(Qe), [po, ot] = et(Qe), mo = (t) => {
  const { __scopePopper: o, children: e } = t, [n, a] = T(null);
  return /* @__PURE__ */ m(po, {
    scope: o,
    anchor: n,
    onAnchorChange: a
  }, e);
}, $o = "PopperAnchor", vo = /* @__PURE__ */ O((t, o) => {
  const { __scopePopper: e, virtualRef: n, ...a } = t, i = ot($o, e), c = W(null), r = K(o, c);
  return B(() => {
    i.onAnchorChange((n == null ? void 0 : n.current) || c.current);
  }), n ? null : /* @__PURE__ */ m(F.div, I({}, a, {
    ref: r
  }));
}), nt = "PopperContent", [ho, Hn] = et(nt), go = /* @__PURE__ */ O((t, o) => {
  var e, n, a, i, c, r, s, l;
  const { __scopePopper: p, side: v = "bottom", sideOffset: C = 0, align: g = "center", alignOffset: h = 0, arrowPadding: u = 0, avoidCollisions: x = !0, collisionBoundary: f = [], collisionPadding: d = 0, sticky: $ = "partial", hideWhenDetached: P = !1, updatePositionStrategy: D = "optimized", onPlaced: te, ...N } = t, w = ot(nt, p), [H, q] = T(null), j = K(
    o,
    (re) => q(re)
  ), [V, Q] = T(null), k = Xt(V), M = (e = k == null ? void 0 : k.width) !== null && e !== void 0 ? e : 0, z = (n = k == null ? void 0 : k.height) !== null && n !== void 0 ? n : 0, Z = v + (g !== "center" ? "-" + g : ""), ce = typeof d == "number" ? d : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...d
  }, J = Array.isArray(f) ? f : [
    f
  ], ge = J.length > 0, oe = {
    padding: ce,
    boundary: J.filter(bo),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: ge
  }, { refs: be, floatingStyles: pe, placement: se, isPositioned: ne, middlewareData: G } = kt({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: Z,
    whileElementsMounted: (...re) => Bt(...re, {
      animationFrame: D === "always"
    }),
    elements: {
      reference: w.anchor
    },
    middleware: [
      Wt({
        mainAxis: C + z,
        alignmentAxis: h
      }),
      x && Vt({
        mainAxis: !0,
        crossAxis: !1,
        limiter: $ === "partial" ? Ut() : void 0,
        ...oe
      }),
      x && Kt({
        ...oe
      }),
      jt({
        ...oe,
        apply: ({ elements: re, rects: me, availableWidth: xe, availableHeight: Ct }) => {
          const { width: Et, height: _t } = me.reference, we = re.floating.style;
          we.setProperty("--radix-popper-available-width", `${xe}px`), we.setProperty("--radix-popper-available-height", `${Ct}px`), we.setProperty("--radix-popper-anchor-width", `${Et}px`), we.setProperty("--radix-popper-anchor-height", `${_t}px`);
        }
      }),
      V && zt({
        element: V,
        padding: u
      }),
      xo({
        arrowWidth: M,
        arrowHeight: z
      }),
      P && Yt({
        strategy: "referenceHidden",
        ...oe
      })
    ]
  }), [b, E] = rt(se), R = $e(te);
  ee(() => {
    ne && (R == null || R());
  }, [
    ne,
    R
  ]);
  const _ = (a = G.arrow) === null || a === void 0 ? void 0 : a.x, y = (i = G.arrow) === null || i === void 0 ? void 0 : i.y, S = ((c = G.arrow) === null || c === void 0 ? void 0 : c.centerOffset) !== 0, [Y, X] = T();
  return ee(() => {
    H && X(window.getComputedStyle(H).zIndex);
  }, [
    H
  ]), /* @__PURE__ */ m("div", {
    ref: be.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...pe,
      transform: ne ? pe.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: Y,
      "--radix-popper-transform-origin": [
        (r = G.transformOrigin) === null || r === void 0 ? void 0 : r.x,
        (s = G.transformOrigin) === null || s === void 0 ? void 0 : s.y
      ].join(" ")
    },
    dir: t.dir
  }, /* @__PURE__ */ m(ho, {
    scope: p,
    placedSide: b,
    onArrowChange: Q,
    arrowX: _,
    arrowY: y,
    shouldHideArrow: S
  }, /* @__PURE__ */ m(F.div, I({
    "data-side": b,
    "data-align": E
  }, N, {
    ref: j,
    style: {
      ...N.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: ne ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: (l = G.hide) !== null && l !== void 0 && l.referenceHidden ? 0 : void 0
    }
  }))));
});
function bo(t) {
  return t !== null;
}
const xo = (t) => ({
  name: "transformOrigin",
  options: t,
  fn(o) {
    var e, n, a, i, c;
    const { placement: r, rects: s, middlewareData: l } = o, v = ((e = l.arrow) === null || e === void 0 ? void 0 : e.centerOffset) !== 0, C = v ? 0 : t.arrowWidth, g = v ? 0 : t.arrowHeight, [h, u] = rt(r), x = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[u], f = ((n = (a = l.arrow) === null || a === void 0 ? void 0 : a.x) !== null && n !== void 0 ? n : 0) + C / 2, d = ((i = (c = l.arrow) === null || c === void 0 ? void 0 : c.y) !== null && i !== void 0 ? i : 0) + g / 2;
    let $ = "", P = "";
    return h === "bottom" ? ($ = v ? x : `${f}px`, P = `${-g}px`) : h === "top" ? ($ = v ? x : `${f}px`, P = `${s.floating.height + g}px`) : h === "right" ? ($ = `${-g}px`, P = v ? x : `${d}px`) : h === "left" && ($ = `${s.floating.width + g}px`, P = v ? x : `${d}px`), {
      data: {
        x: $,
        y: P
      }
    };
  }
});
function rt(t) {
  const [o, e = "center"] = t.split("-");
  return [
    o,
    e
  ];
}
const wo = mo, yo = vo, So = go, Po = /* @__PURE__ */ O((t, o) => {
  var e;
  const { container: n = globalThis == null || (e = globalThis.document) === null || e === void 0 ? void 0 : e.body, ...a } = t;
  return n ? /* @__PURE__ */ Dt.createPortal(/* @__PURE__ */ m(F.div, I({}, a, {
    ref: o
  })), n) : null;
}), Co = [
  " ",
  "Enter",
  "ArrowUp",
  "ArrowDown"
], Eo = [
  " ",
  "Enter"
], Pe = "Select", [Ce, Ee, _o] = At(Pe), [he, kn] = qe(Pe, [
  _o,
  tt
]), Ae = tt(), [To, ue] = he(Pe), [Io, Oo] = he(Pe), No = (t) => {
  const { __scopeSelect: o, children: e, open: n, defaultOpen: a, onOpenChange: i, value: c, defaultValue: r, onValueChange: s, dir: l, name: p, autoComplete: v, disabled: C, required: g } = t, h = Ae(o), [u, x] = T(null), [f, d] = T(null), [$, P] = T(!1), D = Rt(l), [te = !1, N] = He({
    prop: n,
    defaultProp: a,
    onChange: i
  }), [w, H] = He({
    prop: c,
    defaultProp: r,
    onChange: s
  }), q = W(null), j = u ? !!u.closest("form") : !0, [V, Q] = T(/* @__PURE__ */ new Set()), k = Array.from(V).map(
    (M) => M.props.value
  ).join(";");
  return /* @__PURE__ */ m(wo, h, /* @__PURE__ */ m(To, {
    required: g,
    scope: o,
    trigger: u,
    onTriggerChange: x,
    valueNode: f,
    onValueNodeChange: d,
    valueNodeHasChildren: $,
    onValueNodeHasChildrenChange: P,
    contentId: De(),
    value: w,
    onValueChange: H,
    open: te,
    onOpenChange: N,
    dir: D,
    triggerPointerDownPosRef: q,
    disabled: C
  }, /* @__PURE__ */ m(Ce.Provider, {
    scope: o
  }, /* @__PURE__ */ m(Io, {
    scope: t.__scopeSelect,
    onNativeOptionAdd: U((M) => {
      Q(
        (z) => new Set(z).add(M)
      );
    }, []),
    onNativeOptionRemove: U((M) => {
      Q((z) => {
        const Z = new Set(z);
        return Z.delete(M), Z;
      });
    }, [])
  }, e)), j ? /* @__PURE__ */ m(lt, {
    key: k,
    "aria-hidden": !0,
    required: g,
    tabIndex: -1,
    name: p,
    autoComplete: v,
    value: w,
    onChange: (M) => H(M.target.value),
    disabled: C
  }, w === void 0 ? /* @__PURE__ */ m("option", {
    value: ""
  }) : null, Array.from(V)) : null));
}, Do = "SelectTrigger", Ao = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, disabled: n = !1, ...a } = t, i = Ae(e), c = ue(Do, e), r = c.disabled || n, s = K(o, c.onTriggerChange), l = Ee(e), [p, v, C] = dt((h) => {
    const u = l().filter(
      (d) => !d.disabled
    ), x = u.find(
      (d) => d.value === c.value
    ), f = ut(u, h, x);
    f !== void 0 && c.onValueChange(f.value);
  }), g = () => {
    r || (c.onOpenChange(!0), C());
  };
  return /* @__PURE__ */ m(yo, I({
    asChild: !0
  }, i), /* @__PURE__ */ m(F.button, I({
    type: "button",
    role: "combobox",
    "aria-controls": c.contentId,
    "aria-expanded": c.open,
    "aria-required": c.required,
    "aria-autocomplete": "none",
    dir: c.dir,
    "data-state": c.open ? "open" : "closed",
    disabled: r,
    "data-disabled": r ? "" : void 0,
    "data-placeholder": it(c.value) ? "" : void 0
  }, a, {
    ref: s,
    onClick: L(a.onClick, (h) => {
      h.currentTarget.focus();
    }),
    onPointerDown: L(a.onPointerDown, (h) => {
      const u = h.target;
      u.hasPointerCapture(h.pointerId) && u.releasePointerCapture(h.pointerId), h.button === 0 && h.ctrlKey === !1 && (g(), c.triggerPointerDownPosRef.current = {
        x: Math.round(h.pageX),
        y: Math.round(h.pageY)
      }, h.preventDefault());
    }),
    onKeyDown: L(a.onKeyDown, (h) => {
      const u = p.current !== "";
      !(h.ctrlKey || h.altKey || h.metaKey) && h.key.length === 1 && v(h.key), !(u && h.key === " ") && Co.includes(h.key) && (g(), h.preventDefault());
    })
  })));
}), Ro = "SelectValue", Lo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, className: n, style: a, children: i, placeholder: c = "", ...r } = t, s = ue(Ro, e), { onValueNodeHasChildrenChange: l } = s, p = i !== void 0, v = K(o, s.onValueNodeChange);
  return ee(() => {
    l(p);
  }, [
    l,
    p
  ]), /* @__PURE__ */ m(F.span, I({}, r, {
    ref: v,
    style: {
      pointerEvents: "none"
    }
  }), it(s.value) ? /* @__PURE__ */ m(Ne, null, c) : i);
}), Mo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, children: n, ...a } = t;
  return /* @__PURE__ */ m(F.span, I({
    "aria-hidden": !0
  }, a, {
    ref: o
  }), n || "▼");
}), Fo = (t) => /* @__PURE__ */ m(Po, I({
  asChild: !0
}, t)), ve = "SelectContent", Ho = /* @__PURE__ */ O((t, o) => {
  const e = ue(ve, t.__scopeSelect), [n, a] = T();
  if (ee(() => {
    a(new DocumentFragment());
  }, []), !e.open) {
    const i = n;
    return i ? /* @__PURE__ */ Ge(/* @__PURE__ */ m(at, {
      scope: t.__scopeSelect
    }, /* @__PURE__ */ m(Ce.Slot, {
      scope: t.__scopeSelect
    }, /* @__PURE__ */ m("div", null, t.children))), i) : null;
  }
  return /* @__PURE__ */ m(ko, I({}, t, {
    ref: o
  }));
}), ae = 10, [at, fe] = he(ve), ko = /* @__PURE__ */ O((t, o) => {
  const {
    __scopeSelect: e,
    position: n = "item-aligned",
    onCloseAutoFocus: a,
    onEscapeKeyDown: i,
    onPointerDownOutside: c,
    side: r,
    sideOffset: s,
    align: l,
    alignOffset: p,
    arrowPadding: v,
    collisionBoundary: C,
    collisionPadding: g,
    sticky: h,
    hideWhenDetached: u,
    avoidCollisions: x,
    //
    ...f
  } = t, d = ue(ve, e), [$, P] = T(null), [D, te] = T(null), N = K(
    o,
    (b) => P(b)
  ), [w, H] = T(null), [q, j] = T(null), V = Ee(e), [Q, k] = T(!1), M = W(!1);
  B(() => {
    if ($) return Zt($);
  }, [
    $
  ]), Ft();
  const z = U((b) => {
    const [E, ...R] = V().map(
      (S) => S.ref.current
    ), [_] = R.slice(-1), y = document.activeElement;
    for (const S of b)
      if (S === y || (S == null || S.scrollIntoView({
        block: "nearest"
      }), S === E && D && (D.scrollTop = 0), S === _ && D && (D.scrollTop = D.scrollHeight), S == null || S.focus(), document.activeElement !== y)) return;
  }, [
    V,
    D
  ]), Z = U(
    () => z([
      w,
      $
    ]),
    [
      z,
      w,
      $
    ]
  );
  B(() => {
    Q && Z();
  }, [
    Q,
    Z
  ]);
  const { onOpenChange: ce, triggerPointerDownPosRef: J } = d;
  B(() => {
    if ($) {
      let b = {
        x: 0,
        y: 0
      };
      const E = (_) => {
        var y, S, Y, X;
        b = {
          x: Math.abs(Math.round(_.pageX) - ((y = (S = J.current) === null || S === void 0 ? void 0 : S.x) !== null && y !== void 0 ? y : 0)),
          y: Math.abs(Math.round(_.pageY) - ((Y = (X = J.current) === null || X === void 0 ? void 0 : X.y) !== null && Y !== void 0 ? Y : 0))
        };
      }, R = (_) => {
        b.x <= 10 && b.y <= 10 ? _.preventDefault() : $.contains(_.target) || ce(!1), document.removeEventListener("pointermove", E), J.current = null;
      };
      return J.current !== null && (document.addEventListener("pointermove", E), document.addEventListener("pointerup", R, {
        capture: !0,
        once: !0
      })), () => {
        document.removeEventListener("pointermove", E), document.removeEventListener("pointerup", R, {
          capture: !0
        });
      };
    }
  }, [
    $,
    ce,
    J
  ]), B(() => {
    const b = () => ce(!1);
    return window.addEventListener("blur", b), window.addEventListener("resize", b), () => {
      window.removeEventListener("blur", b), window.removeEventListener("resize", b);
    };
  }, [
    ce
  ]);
  const [ge, oe] = dt((b) => {
    const E = V().filter(
      (y) => !y.disabled
    ), R = E.find(
      (y) => y.ref.current === document.activeElement
    ), _ = ut(E, b, R);
    _ && setTimeout(
      () => _.ref.current.focus()
    );
  }), be = U((b, E, R) => {
    const _ = !M.current && !R;
    (d.value !== void 0 && d.value === E || _) && (H(b), _ && (M.current = !0));
  }, [
    d.value
  ]), pe = U(
    () => $ == null ? void 0 : $.focus(),
    [
      $
    ]
  ), se = U((b, E, R) => {
    const _ = !M.current && !R;
    (d.value !== void 0 && d.value === E || _) && j(b);
  }, [
    d.value
  ]), ne = n === "popper" ? je : Bo, G = ne === je ? {
    side: r,
    sideOffset: s,
    align: l,
    alignOffset: p,
    arrowPadding: v,
    collisionBoundary: C,
    collisionPadding: g,
    sticky: h,
    hideWhenDetached: u,
    avoidCollisions: x
  } : {};
  return /* @__PURE__ */ m(at, {
    scope: e,
    content: $,
    viewport: D,
    onViewportChange: te,
    itemRefCallback: be,
    selectedItem: w,
    onItemLeave: pe,
    itemTextRefCallback: se,
    focusSelectedItem: Z,
    selectedItemText: q,
    position: n,
    isPositioned: Q,
    searchRef: ge
  }, /* @__PURE__ */ m(Ht, {
    as: Tt,
    allowPinchZoom: !0
  }, /* @__PURE__ */ m(ao, {
    asChild: !0,
    trapped: d.open,
    onMountAutoFocus: (b) => {
      b.preventDefault();
    },
    onUnmountAutoFocus: L(a, (b) => {
      var E;
      (E = d.trigger) === null || E === void 0 || E.focus({
        preventScroll: !0
      }), b.preventDefault();
    })
  }, /* @__PURE__ */ m(oo, {
    asChild: !0,
    disableOutsidePointerEvents: !0,
    onEscapeKeyDown: i,
    onPointerDownOutside: c,
    onFocusOutside: (b) => b.preventDefault(),
    onDismiss: () => d.onOpenChange(!1)
  }, /* @__PURE__ */ m(ne, I({
    role: "listbox",
    id: d.contentId,
    "data-state": d.open ? "open" : "closed",
    dir: d.dir,
    onContextMenu: (b) => b.preventDefault()
  }, f, G, {
    onPlaced: () => k(!0),
    ref: N,
    style: {
      // flex layout so we can place the scroll buttons properly
      display: "flex",
      flexDirection: "column",
      // reset the outline by default as the content MAY get focused
      outline: "none",
      ...f.style
    },
    onKeyDown: L(f.onKeyDown, (b) => {
      const E = b.ctrlKey || b.altKey || b.metaKey;
      if (b.key === "Tab" && b.preventDefault(), !E && b.key.length === 1 && oe(b.key), [
        "ArrowUp",
        "ArrowDown",
        "Home",
        "End"
      ].includes(b.key)) {
        let _ = V().filter(
          (y) => !y.disabled
        ).map(
          (y) => y.ref.current
        );
        if ([
          "ArrowUp",
          "End"
        ].includes(b.key) && (_ = _.slice().reverse()), [
          "ArrowUp",
          "ArrowDown"
        ].includes(b.key)) {
          const y = b.target, S = _.indexOf(y);
          _ = _.slice(S + 1);
        }
        setTimeout(
          () => z(_)
        ), b.preventDefault();
      }
    })
  }))))));
}), Bo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, onPlaced: n, ...a } = t, i = ue(ve, e), c = fe(ve, e), [r, s] = T(null), [l, p] = T(null), v = K(
    o,
    (N) => p(N)
  ), C = Ee(e), g = W(!1), h = W(!0), { viewport: u, selectedItem: x, selectedItemText: f, focusSelectedItem: d } = c, $ = U(() => {
    if (i.trigger && i.valueNode && r && l && u && x && f) {
      const N = i.trigger.getBoundingClientRect(), w = l.getBoundingClientRect(), H = i.valueNode.getBoundingClientRect(), q = f.getBoundingClientRect();
      if (i.dir !== "rtl") {
        const y = q.left - w.left, S = H.left - y, Y = N.left - S, X = N.width + Y, re = Math.max(X, w.width), me = window.innerWidth - ae, xe = Fe(S, [
          ae,
          me - re
        ]);
        r.style.minWidth = X + "px", r.style.left = xe + "px";
      } else {
        const y = w.right - q.right, S = window.innerWidth - H.right - y, Y = window.innerWidth - N.right - S, X = N.width + Y, re = Math.max(X, w.width), me = window.innerWidth - ae, xe = Fe(S, [
          ae,
          me - re
        ]);
        r.style.minWidth = X + "px", r.style.right = xe + "px";
      }
      const j = C(), V = window.innerHeight - ae * 2, Q = u.scrollHeight, k = window.getComputedStyle(l), M = parseInt(k.borderTopWidth, 10), z = parseInt(k.paddingTop, 10), Z = parseInt(k.borderBottomWidth, 10), ce = parseInt(k.paddingBottom, 10), J = M + z + Q + ce + Z, ge = Math.min(x.offsetHeight * 5, J), oe = window.getComputedStyle(u), be = parseInt(oe.paddingTop, 10), pe = parseInt(oe.paddingBottom, 10), se = N.top + N.height / 2 - ae, ne = V - se, G = x.offsetHeight / 2, b = x.offsetTop + G, E = M + z + b, R = J - E;
      if (E <= se) {
        const y = x === j[j.length - 1].ref.current;
        r.style.bottom = "0px";
        const S = l.clientHeight - u.offsetTop - u.offsetHeight, Y = Math.max(ne, G + (y ? pe : 0) + S + Z), X = E + Y;
        r.style.height = X + "px";
      } else {
        const y = x === j[0].ref.current;
        r.style.top = "0px";
        const Y = Math.max(se, M + u.offsetTop + (y ? be : 0) + G) + R;
        r.style.height = Y + "px", u.scrollTop = E - se + u.offsetTop;
      }
      r.style.margin = `${ae}px 0`, r.style.minHeight = ge + "px", r.style.maxHeight = V + "px", n == null || n(), requestAnimationFrame(
        () => g.current = !0
      );
    }
  }, [
    C,
    i.trigger,
    i.valueNode,
    r,
    l,
    u,
    x,
    f,
    i.dir,
    n
  ]);
  ee(
    () => $(),
    [
      $
    ]
  );
  const [P, D] = T();
  ee(() => {
    l && D(window.getComputedStyle(l).zIndex);
  }, [
    l
  ]);
  const te = U((N) => {
    N && h.current === !0 && ($(), d == null || d(), h.current = !1);
  }, [
    $,
    d
  ]);
  return /* @__PURE__ */ m(Wo, {
    scope: e,
    contentWrapper: r,
    shouldExpandOnScrollRef: g,
    onScrollButtonChange: te
  }, /* @__PURE__ */ m("div", {
    ref: s,
    style: {
      display: "flex",
      flexDirection: "column",
      position: "fixed",
      zIndex: P
    }
  }, /* @__PURE__ */ m(F.div, I({}, a, {
    ref: v,
    style: {
      // When we get the height of the content, it includes borders. If we were to set
      // the height without having `boxSizing: 'border-box'` it would be too big.
      boxSizing: "border-box",
      // We need to ensure the content doesn't get taller than the wrapper
      maxHeight: "100%",
      ...a.style
    }
  }))));
}), je = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, align: n = "start", collisionPadding: a = ae, ...i } = t, c = Ae(e);
  return /* @__PURE__ */ m(So, I({}, c, i, {
    ref: o,
    align: n,
    collisionPadding: a,
    style: {
      // Ensure border-box for floating-ui calculations
      boxSizing: "border-box",
      ...i.style,
      "--radix-select-content-transform-origin": "var(--radix-popper-transform-origin)",
      "--radix-select-content-available-width": "var(--radix-popper-available-width)",
      "--radix-select-content-available-height": "var(--radix-popper-available-height)",
      "--radix-select-trigger-width": "var(--radix-popper-anchor-width)",
      "--radix-select-trigger-height": "var(--radix-popper-anchor-height)"
    }
  }));
}), [Wo, Re] = he(ve, {}), ze = "SelectViewport", Vo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, ...n } = t, a = fe(ze, e), i = Re(ze, e), c = K(o, a.onViewportChange), r = W(0);
  return /* @__PURE__ */ m(Ne, null, /* @__PURE__ */ m("style", {
    dangerouslySetInnerHTML: {
      __html: "[data-radix-select-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-select-viewport]::-webkit-scrollbar{display:none}"
    }
  }), /* @__PURE__ */ m(Ce.Slot, {
    scope: e
  }, /* @__PURE__ */ m(F.div, I({
    "data-radix-select-viewport": "",
    role: "presentation"
  }, n, {
    ref: c,
    style: {
      // we use position: 'relative' here on the `viewport` so that when we call
      // `selectedItem.offsetTop` in calculations, the offset is relative to the viewport
      // (independent of the scrollUpButton).
      position: "relative",
      flex: 1,
      overflow: "auto",
      ...n.style
    },
    onScroll: L(n.onScroll, (s) => {
      const l = s.currentTarget, { contentWrapper: p, shouldExpandOnScrollRef: v } = i;
      if (v != null && v.current && p) {
        const C = Math.abs(r.current - l.scrollTop);
        if (C > 0) {
          const g = window.innerHeight - ae * 2, h = parseFloat(p.style.minHeight), u = parseFloat(p.style.height), x = Math.max(h, u);
          if (x < g) {
            const f = x + C, d = Math.min(g, f), $ = f - d;
            p.style.height = d + "px", p.style.bottom === "0px" && (l.scrollTop = $ > 0 ? $ : 0, p.style.justifyContent = "flex-end");
          }
        }
      }
      r.current = l.scrollTop;
    })
  }))));
}), Uo = "SelectGroup", [Ko, jo] = he(Uo), zo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, ...n } = t, a = De();
  return /* @__PURE__ */ m(Ko, {
    scope: e,
    id: a
  }, /* @__PURE__ */ m(F.div, I({
    role: "group",
    "aria-labelledby": a
  }, n, {
    ref: o
  })));
}), Yo = "SelectLabel", Xo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, ...n } = t, a = jo(Yo, e);
  return /* @__PURE__ */ m(F.div, I({
    id: a.id
  }, n, {
    ref: o
  }));
}), Oe = "SelectItem", [Go, ct] = he(Oe), qo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, value: n, disabled: a = !1, textValue: i, ...c } = t, r = ue(Oe, e), s = fe(Oe, e), l = r.value === n, [p, v] = T(i ?? ""), [C, g] = T(!1), h = K(o, (f) => {
    var d;
    return (d = s.itemRefCallback) === null || d === void 0 ? void 0 : d.call(s, f, n, a);
  }), u = De(), x = () => {
    a || (r.onValueChange(n), r.onOpenChange(!1));
  };
  if (n === "") throw new Error("A <Select.Item /> must have a value prop that is not an empty string. This is because the Select value can be set to an empty string to clear the selection and show the placeholder.");
  return /* @__PURE__ */ m(Go, {
    scope: e,
    value: n,
    disabled: a,
    textId: u,
    isSelected: l,
    onItemTextChange: U((f) => {
      v((d) => {
        var $;
        return d || (($ = f == null ? void 0 : f.textContent) !== null && $ !== void 0 ? $ : "").trim();
      });
    }, [])
  }, /* @__PURE__ */ m(Ce.ItemSlot, {
    scope: e,
    value: n,
    disabled: a,
    textValue: p
  }, /* @__PURE__ */ m(F.div, I({
    role: "option",
    "aria-labelledby": u,
    "data-highlighted": C ? "" : void 0,
    "aria-selected": l && C,
    "data-state": l ? "checked" : "unchecked",
    "aria-disabled": a || void 0,
    "data-disabled": a ? "" : void 0,
    tabIndex: a ? void 0 : -1
  }, c, {
    ref: h,
    onFocus: L(
      c.onFocus,
      () => g(!0)
    ),
    onBlur: L(
      c.onBlur,
      () => g(!1)
    ),
    onPointerUp: L(c.onPointerUp, x),
    onPointerMove: L(c.onPointerMove, (f) => {
      if (a) {
        var d;
        (d = s.onItemLeave) === null || d === void 0 || d.call(s);
      } else
        f.currentTarget.focus({
          preventScroll: !0
        });
    }),
    onPointerLeave: L(c.onPointerLeave, (f) => {
      if (f.currentTarget === document.activeElement) {
        var d;
        (d = s.onItemLeave) === null || d === void 0 || d.call(s);
      }
    }),
    onKeyDown: L(c.onKeyDown, (f) => {
      var d;
      ((d = s.searchRef) === null || d === void 0 ? void 0 : d.current) !== "" && f.key === " " || (Eo.includes(f.key) && x(), f.key === " " && f.preventDefault());
    })
  }))));
}), ye = "SelectItemText", Zo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, className: n, style: a, ...i } = t, c = ue(ye, e), r = fe(ye, e), s = ct(ye, e), l = Oo(ye, e), [p, v] = T(null), C = K(
    o,
    (f) => v(f),
    s.onItemTextChange,
    (f) => {
      var d;
      return (d = r.itemTextRefCallback) === null || d === void 0 ? void 0 : d.call(r, f, s.value, s.disabled);
    }
  ), g = p == null ? void 0 : p.textContent, h = Nt(
    () => /* @__PURE__ */ m("option", {
      key: s.value,
      value: s.value,
      disabled: s.disabled
    }, g),
    [
      s.disabled,
      s.value,
      g
    ]
  ), { onNativeOptionAdd: u, onNativeOptionRemove: x } = l;
  return ee(() => (u(h), () => x(h)), [
    u,
    x,
    h
  ]), /* @__PURE__ */ m(Ne, null, /* @__PURE__ */ m(F.span, I({
    id: s.textId
  }, i, {
    ref: C
  })), s.isSelected && c.valueNode && !c.valueNodeHasChildren ? /* @__PURE__ */ Ge(i.children, c.valueNode) : null);
}), Jo = "SelectItemIndicator", Qo = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, ...n } = t;
  return ct(Jo, e).isSelected ? /* @__PURE__ */ m(F.span, I({
    "aria-hidden": !0
  }, n, {
    ref: o
  })) : null;
}), Ye = "SelectScrollUpButton", en = /* @__PURE__ */ O((t, o) => {
  const e = fe(Ye, t.__scopeSelect), n = Re(Ye, t.__scopeSelect), [a, i] = T(!1), c = K(o, n.onScrollButtonChange);
  return ee(() => {
    if (e.viewport && e.isPositioned) {
      let s = function() {
        const l = r.scrollTop > 0;
        i(l);
      };
      const r = e.viewport;
      return s(), r.addEventListener("scroll", s), () => r.removeEventListener("scroll", s);
    }
  }, [
    e.viewport,
    e.isPositioned
  ]), a ? /* @__PURE__ */ m(st, I({}, t, {
    ref: c,
    onAutoScroll: () => {
      const { viewport: r, selectedItem: s } = e;
      r && s && (r.scrollTop = r.scrollTop - s.offsetHeight);
    }
  })) : null;
}), Xe = "SelectScrollDownButton", tn = /* @__PURE__ */ O((t, o) => {
  const e = fe(Xe, t.__scopeSelect), n = Re(Xe, t.__scopeSelect), [a, i] = T(!1), c = K(o, n.onScrollButtonChange);
  return ee(() => {
    if (e.viewport && e.isPositioned) {
      let s = function() {
        const l = r.scrollHeight - r.clientHeight, p = Math.ceil(r.scrollTop) < l;
        i(p);
      };
      const r = e.viewport;
      return s(), r.addEventListener("scroll", s), () => r.removeEventListener("scroll", s);
    }
  }, [
    e.viewport,
    e.isPositioned
  ]), a ? /* @__PURE__ */ m(st, I({}, t, {
    ref: c,
    onAutoScroll: () => {
      const { viewport: r, selectedItem: s } = e;
      r && s && (r.scrollTop = r.scrollTop + s.offsetHeight);
    }
  })) : null;
}), st = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, onAutoScroll: n, ...a } = t, i = fe("SelectScrollButton", e), c = W(null), r = Ee(e), s = U(() => {
    c.current !== null && (window.clearInterval(c.current), c.current = null);
  }, []);
  return B(() => () => s(), [
    s
  ]), ee(() => {
    var l;
    const p = r().find(
      (v) => v.ref.current === document.activeElement
    );
    p == null || (l = p.ref.current) === null || l === void 0 || l.scrollIntoView({
      block: "nearest"
    });
  }, [
    r
  ]), /* @__PURE__ */ m(F.div, I({
    "aria-hidden": !0
  }, a, {
    ref: o,
    style: {
      flexShrink: 0,
      ...a.style
    },
    onPointerDown: L(a.onPointerDown, () => {
      c.current === null && (c.current = window.setInterval(n, 50));
    }),
    onPointerMove: L(a.onPointerMove, () => {
      var l;
      (l = i.onItemLeave) === null || l === void 0 || l.call(i), c.current === null && (c.current = window.setInterval(n, 50));
    }),
    onPointerLeave: L(a.onPointerLeave, () => {
      s();
    })
  }));
}), on = /* @__PURE__ */ O((t, o) => {
  const { __scopeSelect: e, ...n } = t;
  return /* @__PURE__ */ m(F.div, I({
    "aria-hidden": !0
  }, n, {
    ref: o
  }));
});
function it(t) {
  return t === "" || t === void 0;
}
const lt = /* @__PURE__ */ O((t, o) => {
  const { value: e, ...n } = t, a = W(null), i = K(o, a), c = Gt(e);
  return B(() => {
    const r = a.current, s = window.HTMLSelectElement.prototype, p = Object.getOwnPropertyDescriptor(s, "value").set;
    if (c !== e && p) {
      const v = new Event("change", {
        bubbles: !0
      });
      p.call(r, e), r.dispatchEvent(v);
    }
  }, [
    c,
    e
  ]), /* @__PURE__ */ m(qt, {
    asChild: !0
  }, /* @__PURE__ */ m("select", I({}, n, {
    ref: i,
    defaultValue: e
  })));
});
lt.displayName = "BubbleSelect";
function dt(t) {
  const o = $e(t), e = W(""), n = W(0), a = U((c) => {
    const r = e.current + c;
    o(r), function s(l) {
      e.current = l, window.clearTimeout(n.current), l !== "" && (n.current = window.setTimeout(
        () => s(""),
        1e3
      ));
    }(r);
  }, [
    o
  ]), i = U(() => {
    e.current = "", window.clearTimeout(n.current);
  }, []);
  return B(() => () => window.clearTimeout(n.current), []), [
    e,
    a,
    i
  ];
}
function ut(t, o, e) {
  const a = o.length > 1 && Array.from(o).every(
    (l) => l === o[0]
  ) ? o[0] : o, i = e ? t.indexOf(e) : -1;
  let c = nn(t, Math.max(i, 0));
  a.length === 1 && (c = c.filter(
    (l) => l !== e
  ));
  const s = c.find(
    (l) => l.textValue.toLowerCase().startsWith(a.toLowerCase())
  );
  return s !== e ? s : void 0;
}
function nn(t, o) {
  return t.map(
    (e, n) => t[(o + n) % t.length]
  );
}
const rn = No, ft = Ao, an = Lo, cn = Mo, sn = Fo, pt = Ho, ln = Vo, dn = zo, mt = Xo, $t = qo, vt = Zo, un = Qo, ht = en, gt = tn, bt = on, fn = dn, pn = an, mn = Jt("flex h-10 w-full items-center justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 text-sm  ring-offset-background data-[placeholder]:text-gray-neutral-100 focus:outline-none  disabled:cursor-not-allowed disabled:border-gray-neutral-80 disabled:bg-gray-neutral-10 [&>span]:line-clamp-1", {
  variants: {
    error: {
      true: "border-destructive",
      false: ""
    }
  },
  defaultVariants: {
    error: !1
  }
}), xt = de.forwardRef(({ className: t, children: o, error: e, ...n }, a) => /* @__PURE__ */ A.jsxs(
  ft,
  {
    ref: a,
    className: le(
      mn({ error: e }),
      t
    ),
    ...n,
    children: [
      o,
      /* @__PURE__ */ A.jsx(cn, { asChild: !0, children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowDown", className: "text-black-primary", strokeWidth: 2, width: 18, height: 18 }) })
    ]
  }
));
xt.displayName = ft.displayName;
const Le = de.forwardRef(({ className: t, ...o }, e) => /* @__PURE__ */ A.jsx(
  ht,
  {
    ref: e,
    className: le(
      "flex cursor-default items-center justify-center py-1",
      t
    ),
    ...o,
    children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowUp", width: 16, height: 16 })
  }
));
Le.displayName = ht.displayName;
const Me = de.forwardRef(({ className: t, ...o }, e) => /* @__PURE__ */ A.jsx(
  gt,
  {
    ref: e,
    className: le(
      "flex cursor-default items-center justify-center py-1",
      t
    ),
    ...o,
    children: /* @__PURE__ */ A.jsx(Se, { name: "NavArrowDown", width: 16, height: 16 })
  }
));
Me.displayName = gt.displayName;
const wt = de.forwardRef(({ className: t, children: o, position: e = "popper", ...n }, a) => /* @__PURE__ */ A.jsx(sn, { children: /* @__PURE__ */ A.jsxs(
  pt,
  {
    ref: a,
    className: le(
      "relative z-full-screen max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      e === "popper" && "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
      t
    ),
    position: e,
    ...n,
    children: [
      /* @__PURE__ */ A.jsx(Le, {}),
      /* @__PURE__ */ A.jsx(
        ln,
        {
          className: le(
            "p-1",
            e === "popper" && "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
          ),
          children: o
        }
      ),
      /* @__PURE__ */ A.jsx(Me, {})
    ]
  }
) }));
wt.displayName = pt.displayName;
const yt = de.forwardRef(({ className: t, ...o }, e) => /* @__PURE__ */ A.jsx(
  mt,
  {
    ref: e,
    className: le("px-2 py-1.5 text-sm font-semibold", t),
    ...o
  }
));
yt.displayName = mt.displayName;
const St = de.forwardRef(({ className: t, children: o, ...e }, n) => /* @__PURE__ */ A.jsxs(
  $t,
  {
    ref: n,
    className: le(
      "relative flex w-full hover:bg-gray-50 cursor-pointer select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      t
    ),
    ...e,
    children: [
      /* @__PURE__ */ A.jsx("span", { className: "absolute right-2 flex h-3.5 w-3.5 items-center justify-center", children: /* @__PURE__ */ A.jsx(un, { children: /* @__PURE__ */ A.jsx(Se, { name: "Check", width: 16, height: 16 }) }) }),
      !e.noItemText && /* @__PURE__ */ A.jsx(vt, { children: o }),
      e.noItemText && o
    ]
  }
));
St.displayName = $t.displayName;
const Pt = de.forwardRef(({ className: t, ...o }, e) => /* @__PURE__ */ A.jsx(
  bt,
  {
    ref: e,
    className: le("-mx-1 my-1 h-px bg-muted", t),
    ...o
  }
));
Pt.displayName = bt.displayName;
const Bn = Object.assign(rn, {
  Content: wt,
  Group: fn,
  Item: St,
  Label: yt,
  ScrollDownButton: Me,
  ScrollUpButton: Le,
  Separator: Pt,
  Trigger: xt,
  Value: pn,
  ItemText: vt
});
export {
  Bn as S
};

import { j as a } from "./jsx-runtime-B6kdoens.js";
import { $ as b } from "./index-CWsRuimy.js";
import * as o from "react";
import { c as t } from "./utils-CJ9afRe1.js";
import { I as i } from "./Icon-47FhyXKU.js";
const c = o.forwardRef(({ ...e }, r) => /* @__PURE__ */ a.jsx("nav", { ref: r, "aria-label": "breadcrumb", ...e }));
c.displayName = "Breadcrumb";
const m = o.forwardRef(({ className: e, ...r }, s) => /* @__PURE__ */ a.jsx(
  "ol",
  {
    ref: s,
    className: t(
      "flex overflow-hidden items-center gap-1.5 break-words text-sm text-gray-secondary sm:gap-2.5",
      e
    ),
    ...r
  }
));
m.displayName = "BreadcrumbList";
const d = o.forwardRef(({ className: e, ...r }, s) => /* @__PURE__ */ a.jsx(
  "li",
  {
    ref: s,
    className: t("inline-flex items-center overflow-hidden gap-1.5", e),
    ...r
  }
));
d.displayName = "BreadcrumbItem";
const n = o.forwardRef(({ asChild: e, className: r, ...s }, u) => {
  const x = e ? b : "a";
  return /* @__PURE__ */ a.jsx(
    x,
    {
      ref: u,
      className: t("transition-colors hover:text-foreground", r),
      ...s
    }
  );
});
n.displayName = "BreadcrumbLink";
const l = o.forwardRef(({ className: e, ...r }, s) => /* @__PURE__ */ a.jsx(
  "span",
  {
    ref: s,
    role: "link",
    "aria-disabled": "true",
    "aria-current": "page",
    className: t("font-normal text-foreground", e),
    ...r
  }
));
l.displayName = "BreadcrumbPage";
const p = ({
  children: e,
  className: r,
  ...s
}) => /* @__PURE__ */ a.jsx(
  "li",
  {
    role: "presentation",
    "aria-hidden": "true",
    className: t("[&>svg]:size-3.5", r),
    ...s,
    children: e ?? /* @__PURE__ */ a.jsx(i, { name: "NavArrowRight", strokeWidth: 1.5, width: 16, height: 16 })
  }
);
p.displayName = "BreadcrumbSeparator";
const f = ({
  className: e,
  ...r
}) => /* @__PURE__ */ a.jsxs(
  "span",
  {
    role: "presentation",
    "aria-hidden": "true",
    className: t("flex h-9 w-9 items-center justify-center", e),
    ...r,
    children: [
      /* @__PURE__ */ a.jsx(i, { name: "MoreHoriz", width: 16, height: 16, strokeWidth: 1.5 }),
      /* @__PURE__ */ a.jsx("span", { className: "sr-only", children: "More" })
    ]
  }
);
f.displayName = "BreadcrumbElipssis";
const B = Object.assign(c, {
  List: m,
  Item: d,
  Link: n,
  Page: l,
  Separator: p,
  Ellipsis: f
});
export {
  B as default
};

import { format, parseISO } from "date-fns";
import { AppUserDTO2, ShoeboxItemResponseDto } from "src/backend";
import { ShoeboxItemResponseDtoExtended } from "src/services/lenderShoeBoxApi";

import { getHumanFileSize } from "../file/get-human-file-size";
import { getUserDisplayName } from "../user/get-user-display-name";

export const extendShoeboxItem = (item: ShoeboxItemResponseDto): ShoeboxItemResponseDtoExtended => {
    return {
        ...item,
        lastModifiedDateFormatted: item.document.lastModifiedDate ? format(parseISO(item.document.lastModifiedDate), "MMM d, yyyy") : '',
        lastModifiedByDisplayName: getItemUser(item) ? getUserDisplayName(getItemUser(item)) : '',
        size: item.document.size,
        sizeFormatted: getHumanFileSize(item.document.size),
        lastModifiedByUser: getItemUser(item),
        lastModifiedDate: item.document.lastModifiedDate,
        lastModifiedTimeStamp: new Date(item.document.lastModifiedDate).getTime(),
        whenDeletedFormatted: item.whenDeleted ? format(parseISO(item.whenDeleted), "MMM d, yyyy") : '',
    };
}

const getItemUser = (item: ShoeboxItemResponseDto): AppUserDTO2 => {
    if (item.shoeboxType === 'LOAN') {
        return item.loanRole?.user
    } else if (item.shoeboxType === 'PERSONAL') {
        return item.shoeboxOwner
    }

    return null;
};
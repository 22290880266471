import Stack from "components/dist/atoms/Stack";
import ScreenSizeDropdownMenu from "components/dist/molecules/ScreenSizeDropdownMenu";
import type { ElementStatusType } from "src/backend";

import { FormElementStatusProps, useFormElementStatusState } from "./form-element-status.hook";
import { FormElementStatusAction, FormElementStatusIcon } from "./form-element-status-icon";
import { FormElementStatusRejectConfirm } from "./form-element-status-reject-confirm";

export const FormElementStatusDropdown = (props: FormElementStatusProps) => {
    const state = useFormElementStatusState(props);

    if (!state.hasMenu) return <ScreenSizeDropdownMenu.Item
        asChild
        className="h-12 shrink-0"
        onSelect={() => state.handleStatusChange('ACCEPTED')}>
        <Stack
            row
            justify="start"
            items="center"
            space="sm">
            <FormElementStatusIcon
                width={20}
                iconClassName="text-gray-neutral-100"
                height={20}
                status={state.mainStatusIcon} />
            <FormElementStatusAction status={state.mainStatusIcon} />
        </Stack>
    </ScreenSizeDropdownMenu.Item>

    return <ScreenSizeDropdownMenu.Sub modal={false}>
        <ScreenSizeDropdownMenu.SubTrigger className="h-12 shrink-0 p-0">
            <Stack
                row
                space="sm"
                className="items-center w-full ">
                <FormElementStatusIcon
                    width={20}
                    iconClassName="text-gray-neutral-100"
                    height={20}
                    status={state.mainStatusIcon} />
                <FormElementStatusAction status={state.mainStatusIcon} />
            </Stack>
        </ScreenSizeDropdownMenu.SubTrigger>
        {state.menuItems.length > 0 && <ScreenSizeDropdownMenu.SubContent>
            {state.menuItems.map((status: ElementStatusType) => (
                <ScreenSizeDropdownMenu.Item key={status}
                    asChild
                    onSelect={(event) => {
                        state.handleStatusChange(status)
                        if (status === 'REJECTED') {
                            event.preventDefault();
                        }
                    }}>
                    <Stack
                        row
                        justify="start"
                        items="center"
                        space="xs">
                        <FormElementStatusIcon width={20} height={20} status={status} />
                        <FormElementStatusAction status={status} />
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
            ))}
        </ScreenSizeDropdownMenu.SubContent>}
        {state.isRejectConfirmOpen && <FormElementStatusRejectConfirm
            id={props.id}
            loanId={props.loanId}
            open={state.isRejectConfirmOpen}
            onOpenChange={state.setIsRejectConfirmOpen}
            onConfirm={({ note, includeWithMessage }) => state.handleStatusChangeConfirm({
                status: 'REJECTED',
                note,
                includeWithMessage
            })}
            title={props.title}
        />}
    </ScreenSizeDropdownMenu.Sub>
}

import { useListener } from "polyrhythm-react";
import { useLayoutEffect, useState, useTransition } from "react";
import { useUser } from "src/hooks/use-user";
import { useGetLoanElementsQuery } from "src/services/packageApi";
import { FormElementV2ResponseDtoExtended } from "src/types/formelement";
import { findSectionByIdAndItsAncestors } from "src/utils";

import { ICopyMoveToDialog } from "./copy-move-to-dialog.types";

const getTitle = (operation: ICopyMoveToDialog['props']['operation'], elements: ICopyMoveToDialog['props']['elements']) => {
    // Copy {{Form/File title}}/{{n items}} to
    // Move {{Form/File title}}/{{n items}} to
    // if we only have one item, we should use the title of the item
    // if we have multiple items, we should show n items
    const title = elements.length === 1 ? elements[0].title : `${elements.length} items`;
    return `${operation === 'COPY' ? 'Copy' : 'Move'} ${title} to`;
}

const getActonText = (operation: ICopyMoveToDialog['props']['operation'], element: FormElementV2ResponseDtoExtended) => {
    if (!element) return "Copy to"
    // Copy/Move {{Form/File title}} to {{Folder title}}
    return `${operation === 'COPY' ? 'Copy' : 'Move'} to ${element.title}`;
}

const MAX_BREADCRUMB_ITEMS = 3;

export const useCopyMoveToDialogState = (props: ICopyMoveToDialog['props']) => {
    const [activeElement, setActiveElement] = useState<FormElementV2ResponseDtoExtended>(null);
    const userState = useUser();
    const [isPending, setTransition] = useTransition();
    const [firstElement] = props.type === "ELEMENTS" ? props.elements : [];
    const { data = { list: [], rootElementId: null } } = useGetLoanElementsQuery({
        id: props.loanId,
        view: 'CONVENTIONAL',
        status: 'ACTIVE'
    }, {
        skip: !props.loanId
    });

    const onElementClick = (element: FormElementV2ResponseDtoExtended) => {
        setTransition(() => {
            setActiveElement(prevElement => {
                // if the element is already selected, deselect it
                // by selecting it's parent
                if (prevElement?.id === element.id) {
                    return data.list.find(e => e.id === element.parentId);
                }
                return element;
            });
        });
    }

    let activeFolderElementId = data?.rootElementId
    if (activeElement && activeElement.storageType === "FOLDER") {
        activeFolderElementId = activeElement.id
    } else if (activeElement?.parentId) {
        activeFolderElementId = activeElement.parentId
    }
    const activeList = data?.list.filter(element => element.parentId === activeFolderElementId || (element.parentId === data?.rootElementId && !activeFolderElementId))
    const rootElement = data?.list.find(element => element.id === data?.rootElementId);
    const elementsBreadcrumbs = findSectionByIdAndItsAncestors(activeFolderElementId, [...data ? data.list : []]).reverse()

    // selecting files will be disabled if one of source element does not have an answer
    const selectedFileDisallowed = props.type === "ELEMENTS" && props.elements.some(element => !element.answer);

    const onConfirmClick = () => {
        props.onConfirm(activeElement ?? rootElement);
    };

    const onCopyHereClick = (element: FormElementV2ResponseDtoExtended) => {
        props.onConfirm(element);
    }

    const onCancelClick = () => {
        // reset the active element
        setTransition(() => {
            setActiveElement(null);
            props.onCancel();
        });
    }

    const onDialogOpenChange = (open: boolean) => {
        if (!open) {
            onCancelClick();
            setActiveElement(null);
            // set the active element to the parent of the first element
            // if the first element is a file
        }
    }

    const onNewFolderClick = () => {
        const folderElement = data.list.find(e => e.storageType === "FOLDER" && e.id === activeFolderElementId);
        if (folderElement) {
            props.onNewFolder(folderElement);
        }
    }

    const isElementDisabled = (element: FormElementV2ResponseDtoExtended) => {
        const files = props.type === "ELEMENTS" ? props.elements.filter(e => e.storageType === "FILE") : [];
        const folders = props.type === "ELEMENTS" ? props.elements.filter(e => e.storageType === "FOLDER") : [];

        return (selectedFileDisallowed && element.storageType === "FILE") ||
            // do not allow copying moving a file to itself
            files.map(e => e.id).includes(element.id) ||
            // do not allow moving a folder to itself
            (folders.map(e => e.id).includes(element.id) && props.operation === 'MOVE');
    }

    useListener('/elements/folder/created', (event) => {
        setActiveElement(event.payload);
    });

    const doesNotHaveSourceElements = props.type === "ELEMENTS" && props.elements.length === 0;
    useLayoutEffect(function setInitialBreadCrumbFolder() {
        // if no element is selected, select the first element
        if (!activeElement && data?.list.length && props.type === "ELEMENTS" && !doesNotHaveSourceElements) {
            const elementInConventional = data.list.find(e => e.id === firstElement?.id);
            const elementParentInConventional = data?.list.find(e => e.id === elementInConventional?.parentId);
            if (elementParentInConventional) {
                setActiveElement(elementParentInConventional);
            }
        } else if (doesNotHaveSourceElements) {
            setActiveElement(null);
        }
    }, [activeElement, data?.list, firstElement?.id, doesNotHaveSourceElements, props.type]);

    const fullBreadcrumbs = [...rootElement ? [rootElement] : [], ...elementsBreadcrumbs];
    // only show last 3 breadcrumbs
    const breadcrumbs = fullBreadcrumbs.slice(-MAX_BREADCRUMB_ITEMS);
    const collapsedBreadcrumbs: FormElementV2ResponseDtoExtended[] = fullBreadcrumbs.length > breadcrumbs.length
        ? fullBreadcrumbs.slice(0, fullBreadcrumbs.length - MAX_BREADCRUMB_ITEMS)
        : [];

    return {
        selectedFileDisallowed,
        rootElement,
        activeFolderElementId,
        activeElement,
        actionText: getActonText(props.operation, activeElement ?? rootElement),
        title: getTitle(props.operation, props.elements),
        disabledElementsIds: props.elements.map(e => e.id),
        collapsedBreadcrumbs,
        onNewFolderClick,
        onElementClick,
        onDialogOpenChange,
        onCancelClick,
        onConfirmClick,
        isElementDisabled,
        onCopyHereClick,
        list: activeList ?? [],
        loggedInUserId: userState.user.id,
        breadcrumbs,
    } as const;
}
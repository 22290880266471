import { j as a } from "./jsx-runtime-B6kdoens.js";
import "./Accordion-B1W0sq0-.js";
import "./Avatar-D2CKq5wD.js";
import { B as j } from "./Badge-C0bj4DFU.js";
import "./Button-DhTfdjdw.js";
import "./DropdownMenu-vsFlyIPX.js";
import { I as i } from "./Icon-47FhyXKU.js";
import "./Input-vl7Xx2ol.js";
import "./Label-BKL8LTmX.js";
import { T as m } from "./Text-DOFuHqUo.js";
import "./Tooltip-ae2E5kx6.js";
import { g as e, c as g } from "./utils-CJ9afRe1.js";
import { S as t } from "./Stack-9Jnn74Nm.js";
import "./Switch-DaK2TV5Z.js";
import "./Toast-y6W489IG.js";
import "react";
import "./Toggle-DH_2vm0p.js";
const s = ({
  children: o,
  className: r = ""
}) => {
  const f = e(o, "Name"), y = e(
    o,
    "Icon",
    /* @__PURE__ */ a.jsx(i, { name: "ClientFolder", width: 20 })
  ), x = e(o, "PrimaryInfo"), I = e(o, "SecondaryInfo"), N = e(o, "Badge");
  return /* @__PURE__ */ a.jsxs(
    t,
    {
      row: !0,
      space: "md",
      className: g(
        "p-4 bg-white w-fit folder-border rounded-md md:min-w-[360px]",
        r
      ),
      children: [
        /* @__PURE__ */ a.jsx(t, { row: !0, space: "md", className: "items-start", children: y }),
        /* @__PURE__ */ a.jsxs(t, { space: "md", children: [
          f,
          /* @__PURE__ */ a.jsxs(t, { row: !0, space: "md", className: "items-center", children: [
            x,
            I,
            N
          ] })
        ] })
      ]
    }
  );
}, n = ({ name: o, ...r }) => /* @__PURE__ */ a.jsx(i, { name: o, width: 20, height: 20, ...r });
n.displayName = "Icon";
s.Icon = n;
const c = ({ children: o }) => /* @__PURE__ */ a.jsx(m, { size: "base", className: "leading-5", children: o });
c.displayName = "Name";
s.Name = c;
const d = ({ children: o }) => /* @__PURE__ */ a.jsx(m, { size: "xs", variant: "primary", children: o });
d.displayName = "PrimaryInfo";
s.PrimaryInfo = d;
const p = ({ children: o }) => /* @__PURE__ */ a.jsx(m, { size: "xs", variant: "secondary", children: o });
p.displayName = "SecondaryInfo";
s.SecondaryInfo = p;
const l = ({
  children: o,
  className: r
}) => /* @__PURE__ */ a.jsx(j, { className: r, variant: "destructive", children: o });
l.displayName = "Badge";
s.Badge = l;
export {
  s as C
};

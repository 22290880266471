import { ColorPalletteListItem } from "./color-pallette-list-item"

const Colors = [
    "#FAC014",
    "#D84F33",
    "#E1792A",
    "#589B51",
    "#46918E",
    "#3A84CC",
    "#9268C3",
    "#C060B1",
    "#B1B7BA",
    "#F7BFB4",
    "#F7C18A",
    "#9CCF94",
    "#8CCECD",
    "#92C7F4",
    "#CFB3F3",
    "#EDB0E5",
]

interface ColorPalletteListProps {
    onColorSelected?: (color: string) => void
    activeColor?: string
}

export const ColorPalletteList = (props: ColorPalletteListProps) => {
    return Colors.map((hexColor) => (<ColorPalletteListItem
        key={hexColor}
        isActive={props.activeColor === hexColor}
        onColorSelected={props.onColorSelected}
        hexColor={hexColor} />))
}